import { AxiosError } from "axios";
import { AbstractBaseService } from "../ensue-react-system/utilities/base-service";
import { ILoginResponse } from "../commons/models/i-login-response";
import { LoginResponse } from "../commons/models/login-response";
import { EnsueHttpClient } from "../ensue-react-system/http/ensue-http-client";
import { ApiEndPointUriProvider } from "../ensue-react-system/http/ensue-http-types";
import { User } from "../commons/models/user";


interface ICredential {
    username: string,
    password: string,
}

export class LoginService extends AbstractBaseService<LoginResponse> {
    protected __model: LoginResponse = new LoginResponse();
    
    public constructor(__client: EnsueHttpClient, __endpointProvider: ApiEndPointUriProvider) {
        super(__client, __endpointProvider);
    }

    public getResourceName(): string {
        return "auth/login";
    }

    public login(credentials: ICredential): Promise<ILoginResponse> {
        return this.request<ICredential,ILoginResponse>(this.__url, 'post',credentials)
        .then(e => {
            e.user = (new User()).create(e.user) as User;
            return e;
        });
    }

}
