import { IBasicInfo } from "../../../commons/components/interface";
import {
  AppBox,
  AppGridBox,
  AppPaper,
  AppText,
  InfoCard,
} from "../../../commons/components";
interface BasicInfoProps {
  basicInfo: IBasicInfo;
}
export default function BasicInfoCard({ basicInfo }: BasicInfoProps) {
  const basicInfoList = [
    {
      label: "Total Player",
      title: basicInfo.totalPlayer,
    },
    {
      label: "Foundation",
      title: basicInfo.foundation,
    },
  ];
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppGridBox columns={3} gap="sm">
        {basicInfoList.map((item, index) => (
          <AppPaper shadow="xs" padding="none" key={index}>
            <InfoCard
              label={item.label}
              title={item.title}
              alignItems="start"
            ></InfoCard>
          </AppPaper>
        ))}
      </AppGridBox>
      <AppText as="p" fontWeight="medium" size="lg">
        {basicInfo.description}
      </AppText>
    </AppBox>
  );
}
