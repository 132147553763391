import NewsFIrstImage from "../../assets/images/news/image 130.png";
import NewsSecondImage from "../../assets/images/news/Image Block.png";
import NewsThirdImage from "../../assets/images/news/Image.png";
import NewsFourthImage from "../../assets/images/news/Player.png";
import { AppBox, AppGridBox } from "../../commons/components";
import NewsTimelineCard from "../../commons/components/ui-components/NewsTimelineCard";
import TeamImage from "../../assets/images/news/team.png";
import NewsSecond from "../../assets/images/news/ProfileNews1.png";
import NewsThird from "../../assets/images/news/ProfileNews2.png";
import NewsFourth from "../../assets/images/news/ProfileNews3.png";

export default function PlayerNews() {
  const latestNews = [
    {
      id: 1,
      date: new Date("2023/06/20"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFIrstImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFourthImage} alt="" />,
        },
      ],
    },
    {
      id: 2,
      date: new Date("2023/07/22"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecondImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
      ],
    },
  ];

  const news = [
    {
      id: 1,
      date: new Date("2023/05/10"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton. He was really good and I remember a few teams looking at him, he was a really good player.",
          media: <img src={NewsFIrstImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecondImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={TeamImage} alt="" />,
        },
      ],
    },
    {
      id: 2,
      date: new Date("2023/01/08"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecond} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThird} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFourth} alt="" />,
        },
      ],
    },
  ];

  return (
    <AppGridBox className="page-content" gap="md">
      <NewsTimelineCard news={news} timelineType="big" />
      <AppBox displayBlock>
        <NewsTimelineCard
          title="Latest News"
          news={latestNews}
          timelineType="small"
        />
      </AppBox>
    </AppGridBox>
  );
}
