export default function IconInfo() {
  return (
    <svg
      className="svg-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0011 1.66699C14.6042 1.66699 18.3357 5.39853 18.3357 10.0016C18.3357 14.6047 14.6042 18.3362 10.0011 18.3362C5.39804 18.3362 1.6665 14.6047 1.6665 10.0016C1.6665 5.39853 5.39804 1.66699 10.0011 1.66699ZM10.0011 2.91699C6.0884 2.91699 2.9165 6.08888 2.9165 10.0016C2.9165 13.9144 6.0884 17.0862 10.0011 17.0862C13.9139 17.0862 17.0857 13.9144 17.0857 10.0016C17.0857 6.08888 13.9139 2.91699 10.0011 2.91699ZM9.99805 8.75078C10.3145 8.75062 10.5761 8.98553 10.6177 9.29062L10.6235 9.37537L10.6265 13.96C10.6267 14.3052 10.3471 14.5853 10.0019 14.5855C9.68547 14.5857 9.4238 14.3507 9.38222 14.0457L9.37647 13.9609L9.37347 9.3762C9.3733 9.03103 9.65289 8.75103 9.99805 8.75078ZM10.0015 5.836C10.4611 5.836 10.8337 6.2086 10.8337 6.66823C10.8337 7.12784 10.4611 7.50044 10.0015 7.50044C9.54189 7.50044 9.1693 7.12784 9.1693 6.66823C9.1693 6.2086 9.54189 5.836 10.0015 5.836Z"
        fill="currentColor"
      />
    </svg>
  );
}
