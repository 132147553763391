import {
  AppAvatar,
  AppBox,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../commons/components";

interface TournamentTeamCardProps {
  avatar: string;
  title: string;
  label: string;
}
export default function TournamentTeamCard({
  title,
  label,
  avatar,
}: TournamentTeamCardProps) {
  return (
    <AppPaper radius="sm" padding="xs" shadow="xs">
      <AppBox gap="xs">
        <AppAvatar username="" src={avatar} />
        <AppBox flexDirection="column">
          <AppTitle as={"h5"}>{title}</AppTitle>
          <AppBox gap="3xs">
            <AppText as="label" size="sm" color="muted">
              {"Foundation Date :"}
            </AppText>
            <AppText as="label" size="sm" color="muted">
              {label}
            </AppText>
          </AppBox>
        </AppBox>
      </AppBox>
    </AppPaper>
  );
}
