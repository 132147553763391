import { useState } from "react";
import { useAppDispatch } from "../hooks/app";
import { HOME_PATH } from "../routes";
import { login } from "../redux/slices/auth-slice";

const Login = () => {
  const dispatch = useAppDispatch();

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const onLogin = async (e: any) => {
    e.preventDefault();
    const result = await dispatch(login({ username, password }));
    if (!Object.hasOwn(result, "error")) {
      window.location.pathname = HOME_PATH;
    }
    return false;
  };
  return (
    <div>
      <h4>Tali</h4>
      <h5>Sign in</h5>
      <form onSubmit={onLogin} method="post">
        <div>
          <label>Username</label>
          <input name="username" />
        </div>
        <div>
          <label>Password</label>
          <input name="password" />
        </div>
        <div>
          <button type="submit">Login</button>
        </div>
      </form>
    </div>
  );
};

export default Login;
