import TeamStandingTable from "./components/TeamStandingTable";
import NewsFIrstImage from "../../assets/images/news/image 130.png";
import NewsSecondImage from "../../assets/images/news/Image Block.png";
import NewsThirdImage from "../../assets/images/news/Image.png";
import NewsFourthImage from "../../assets/images/news/Player.png";

import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppTitle,
} from "../../commons/components";
import IconInfo from "../../commons/components/icons/info";
import NewsTimelineCard from "../../commons/components/ui-components/NewsTimelineCard";

export default function TeamStanding() {
  const news = [
    {
      id: 1,
      date: new Date("2023/06/20"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFIrstImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFourthImage} alt="" />,
        },
      ],
    },
    {
      id: 2,
      date: new Date("2023/07/22"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecondImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
      ],
    },
  ];

  const leagues = ["Premier League", "Laliga", "League 1", "FA Cup"];
  const Season = ["2022/23", "2021/22", "2020/21"];
  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox flexDirection="column" gap="sm">
        <AppBox flexDirection="column" gap="xs">
          <AppBox justifyContent="space-between">
            <AppTitle as="h5">Team Standings</AppTitle>
            <IconInfo />
          </AppBox>
          <AppGridBox columns={2} gap="sm">
            <AppSelect options={leagues} />
            <AppSelect options={Season} />
          </AppGridBox>
        </AppBox>
        <AppBox displayBlock>
          <TeamStandingTable
            activeValue={[1, 2]}
            activeColors={["primary", "info"]}
          />
        </AppBox>
      </AppBox>
      <AppBox displayBlock>
        <NewsTimelineCard news={news} timelineType="small" />
      </AppBox>
    </AppGridBox>
  );
}
