import {
  AppAvatar,
  AppBox,
  AppPaper,
  AppScorePill,
  AppText,
  AppTitle,
} from "../../../commons/components";
import HeaderBackGround from "../../../assets/images/backgrounds/Elements.svg";
import AppTabs, { TabItem } from "../../../commons/components/Tabs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IMatch } from "../../../commons/components/interface/i-match";

interface MatchDetailHeaderProps {
  match: IMatch;
}

export default function MatchDetailHeader({ match }: MatchDetailHeaderProps) {
  const [currentTab, setCurrentTab] = useState("summary");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const location = useLocation();

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  const tabItems = [
    {
      label: "Summary",
      value: "summary",
      href: "summary",
    },
    {
      label: "Commentary",
      value: "commentary",
      href: "commentary",
    },
    {
      label: "Lineups",
      value: "lineups",
      href: "lineups",
    },
    {
      label: "Statistics",
      value: "statistics",
      href: "statistics",
    },
    {
      label: "Standings",
      value: "standings",
      href: "standings",
    },
  ];
  return (
    <AppPaper shadow="xs" padding="none">
      <AppBox
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="xs"
        p="sm"
        style={{
          backgroundImage: `url(${HeaderBackGround})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          borderBottom: "1px solid rgb(var(--border-default))",
        }}
      >
        <AppText color="muted" textAlign="center">
          {match.league}
        </AppText>
        <AppBox gap="xl" alignItems='center'>
          <AppBox alignItems="center" gap="xs">
            <AppTitle as="h4">{match.teamA.name}</AppTitle>
            <AppAvatar
              username={match.teamA.name}
              size="2xl"
              variant="outline"
              src={match.teamA.logo}
            />
          </AppBox>
          <AppScorePill matchStatus='live' valueFirst={match.teamA.goal} valueSecond={match.teamB.goal} />
          <AppBox alignItems="center" gap="xs">
            <AppAvatar
              username={match.teamB.name}
              size="2xl"
              variant="outline"
              src={match.teamB.logo}
            />
            <AppTitle as="h4">{match.teamB.name}</AppTitle>
          </AppBox>
        </AppBox>
      </AppBox>
      <AppBox p="sm">
        <AppTabs
          as="a"
          onClick={(e) => setActiveItem(e)}
          activeValue={currentTab}
          tabItems={tabItems}
        />
      </AppBox>
    </AppPaper>
  );
}
