import { AppBox } from "../../../commons/components";
import Hampton from "../../../assets/images/clubs/WestHam.png";
import Brighton from "../../../assets/images/clubs/Brighton.png";
import Arsenal from "../../../assets/images/clubs/Arsenal.png";
import Nottingham from "../../../assets/images/clubs/Nottingham.png";
import Liverpool from "../../../assets/images/clubs/Liverpool.png";
import MatchResultCard from "../../team-detail/components/MatchResultCard";

export default function MatchFormTeam1() {
  const matchResult = [
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      status: "W",
      teamB: {
        name: "W. Hampton",
        logo: Hampton,
        goal: 0,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 5,
      },
    },
    {
      id: 2,
      date: "29 May, 2023",
      league: "English Premier League",
      status: "L",
      teamA: {
        name: "Nottingham",
        logo: Nottingham,
        goal: 1,
      },
      teamB: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 0,
      },
    },
    {
      id: 3,
      date: "01 May, 2023",
      league: "English Premier League",
      status: "L",
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 1,
      },
      teamB: {
        name: "Brighton",
        logo: Brighton,
        goal: 2,
      },
    },
    {
      id: 4,
      date: "02 May, 2023",
      league: "English Premier League",
      status: "W",
      teamA: {
        name: "Liverpool",
        logo: Liverpool,
        goal: 1,
      },
      teamB: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 2,
      },
    },
    {
      id: 5,
      date: "01 May, 2023",
      league: "English Premier League",
      status: "L",
      teamA: {
        name: "Liverpool",
        logo: Liverpool,
        goal: 3,
      },
      teamB: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 2,
      },
    },
  ];
  return (
    <AppBox flexDirection="column" gap="sm" pl="xs">
      {matchResult.map((result) => (
        <MatchResultCard key={result.id} data={result} />
      ))}
    </AppBox>
  );
}
