import { AppBox, AppPaper, AppText, InfoCard } from "../../../commons/components";
import AppGridBox from "../../../commons/components/GridBox";
import { ITournamentBasicInfo } from "../../../commons/components/interface/i-tournament";

interface TournamentBasicInfoCardProps {
  tournamentData: ITournamentBasicInfo;
}

export default function TournamentBasicInfoCard({ tournamentData }: TournamentBasicInfoCardProps) {
  const basicInfoList = [
    {
      title: "Tournament Type",
      label: tournamentData.tournamentType,
    },
    {
      title: "Duration",
      label: tournamentData.duration,
    },
    {
      title: "Team's Gender",
      label: tournamentData.teamsGender,
    },
    {
      title: "Team's Age",
      label: tournamentData.teamsAge,
    },
    {
      title: "Team Formation",
      label: tournamentData.teamFormation,
    },
    {
      title: "Team's Skill Level",
      label: tournamentData.teamSkillLevel,
    },
  ];
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppGridBox columns={3} gap="sm">
        {basicInfoList.map((item, index) => (
          <AppPaper shadow="xs" padding="none" key={index}>
            <InfoCard label={item.title} title={item.label} alignItems="start"></InfoCard>
          </AppPaper>
        ))}
      </AppGridBox>
      <AppText as="p" fontWeight="medium" size="lg">
        {tournamentData.description}
      </AppText>
    </AppBox>
  );
}
