import AppActionIcon from "../ActionIcon";
import AppBox from "../Box";
import IconCalendar from "../icons/calendar";

export default function DatePicker() {
  return (
    /**
     * ToDo: Implement a working date picker.
     */
    <AppBox className="datePickerContainer">
      <input type="date" className="customDatePicker ee-input" />
      <AppActionIcon
        icon={<IconCalendar />}
        color="gray"
        variant="default"
        className="calendarIcon"
      />
    </AppBox>
  );
}
