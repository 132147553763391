import { ReactNode } from "react";
import { Colors, Sizes } from "../types/core-types";

interface ButtonProps {
  as?: "button" | "a";
  color?: Colors;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  label: string;
  leftSection?: ReactNode;
  radius?: Sizes;
  rightSection?: ReactNode;
  size?: Sizes;
  variant?: "filled" | "outline" | "light" | "subtle";
}

export default function AppButton({
  as = "button",
  color = "primary",
  disabled,
  label,
  leftSection,
  loading,
  radius = "sm",
  rightSection,
  size = "md",
  variant = "filled",
}: ButtonProps) {
  const classNames = [
    `btn`,
    variant && `btn-${variant}`,
    color && `color-${color}`,
    size && `size-${size}`,
    // radius && `radius-${radius}`,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <button
      className={classNames}
      data-variant={variant}
      data-disabled={disabled}
      data-loading={loading}
      aria-label={label}
      aria-disabled={disabled}>
      {loading && <>laoding</>}
      {leftSection && leftSection}
      <span>{label}</span>
      {rightSection && rightSection}
    </button>
  );
}
