import PlayerCard from "./components/PlayerCard";
import Aaron from "../../assets/images/players/Ramsdale.png";
import Matt from "../../assets/images/players/Turner.png";
import William from "../../assets/images/players/Saliba.png";
import White from "../../assets/images/players/White.png";
import Grbriel from "../../assets/images/players/Jesus.png";
import Pepe from "../../assets/images/players/Pepe.png";
import Martin from "../../assets/images/players/Odegaard.png";
import Jorginho from "../../assets/images/players/Jorginho.png";
import Fabio from "../../assets/images/players/Vieira.png";
import Albert from "../../assets/images/players/Lokonga.png";
import Eddie from "../../assets/images/players/Nketiah.png";
import Nelson from "../../assets/images/players/Nelson.png";
import Kieran from "../../assets/images/players/Tierney.png";
import Jakub from "../../assets/images/players/Kiwior.png";
import { AppBox, AppGridBox, AppTitle } from "../../commons/components";
import NewsTimelineCard from "../../commons/components/ui-components/NewsTimelineCard";
import NewsFIrstImage from "../../assets/images/news/image 130.png";
import NewsSecondImage from "../../assets/images/news/Image Block.png";
import NewsThirdImage from "../../assets/images/news/Image.png";
import NewsFourthImage from "../../assets/images/news/Player.png";

export default function TeamSquad() {
  const squad = [
    {
      id: 1,
      position: "Goalkeepers",
      player: [
        {
          id: 11,
          name: "Aaron Ramsdale",
          avatar: Aaron,
          number: 1,
          position: "Goalkeeper",
        },
        {
          id: 12,
          name: "Matt Turner",
          avatar: Matt,
          number: 30,
          position: "Goalkeeper",
        },
      ],
    },
    {
      id: 2,
      position: "Defenders",
      player: [
        {
          id: 21,
          name: "William Saliba",
          avatar: William,
          number: 10,
          position: "defender",
        },
        {
          id: 22,
          name: "Kieran Tierney",
          avatar: Kieran,
          number: 11,
          position: "Defender",
        },
        {
          id: 23,
          name: "Ben White",
          avatar: White,
          number: 20,
          position: "Defender",
        },
        {
          id: 24,
          name: "Jakub Kiwior",
          avatar: Jakub,
          number: 25,
          position: "Defender",
        },
      ],
    },
    {
      id: 3,
      position: "Forwards",
      player: [
        {
          id: 31,
          name: "Gabriel Jesus",
          avatar: Grbriel,
          number: 21,
          position: "Forward",
        },
        {
          id: 32,
          name: "Eddie Nketiah",
          avatar: Eddie,
          number: 14,
          position: "Forward",
        },
        {
          id: 33,
          name: "Nicolas Pepe",
          avatar: Pepe,
          number: 19,
          position: "Forward",
        },
        {
          id: 34,
          name: "Reiss Nelson",
          avatar: Nelson,
          number: 19,
          position: "Forward",
        },
      ],
    },
    {
      id: 4,
      position: "Midfielders",
      player: [
        {
          id: 41,
          name: "Martin Odegaard",
          avatar: Martin,
          number: 80,
          position: "Midfielder",
        },
        {
          id: 42,
          name: "Jorginho",
          avatar: Jorginho,
          number: 20,
          position: "Midfielder",
        },
        {
          id: 43,
          name: "Fabio Vieira",
          avatar: Fabio,
          number: 21,
          position: "Midfielder",
        },
        {
          id: 44,
          name: "Albert Sambi Lokonga",
          avatar: Albert,
          number: 23,
          position: "Midfielder",
        },
      ],
    },
  ];

  const news = [
    {
      id: 1,
      date: new Date("2023/06/20"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFIrstImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFourthImage} alt="" />,
        },
      ],
    },
    {
      id: 2,
      date: new Date("2023/07/22"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecondImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
      ],
    },
  ];

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox flexDirection="column" gap="md">
        {squad.map((item) => (
          <AppBox key={item.id} flexDirection="column" gap="xs">
            <AppTitle as="h5">{item.position}</AppTitle>
            <AppGridBox columns={2} gap="sm">
              {item.player.map((player) => (
                <PlayerCard key={player.id} playerInfo={player} />
              ))}
            </AppGridBox>
          </AppBox>
        ))}
      </AppBox>
      <AppBox displayBlock>
        <NewsTimelineCard news={news} timelineType="small" />
      </AppBox>
    </AppGridBox>
  );
}
