import { CSSProperties } from "react";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppGridBox,
  AppPaper,
  AppResultBadge,
  AppScorePill,
  AppText,
  AppTitle,
} from "../../../commons/components";
import AppBorderBox from "../../../commons/components/BorderBox";

interface MatchProps {
  date?: string;
  league?: string;
  status?: string;
  teamA: { logo: string; name: string; goal?: number };
  teamB: {
    logo: string;
    name: string;
    goal?: number;
  };
}

export default function MatchResultCard({ data }: { data: MatchProps }) {
  return (
    <AppPaper className="match-card" shadow="xs" padding="xs">
      <AppGridBox className="match-card-grid" gap="sm">
        {data.status && (
          <AppBox className="status-badge">
            <AppResultBadge status={data.status} />
          </AppBox>
        )}
        <AppGridBox className="align-center left-section" gap="xs">
          <AppBox gap="xs" justifyContent="end" alignItems="center">
            <AppTitle truncate={true} as="h6">
              {data.teamA.name}
            </AppTitle>
            <AppAvatar username={data.teamA.name} src={data.teamA.logo} />
          </AppBox>
          <AppBox justifyContent="center">
            {/* The design of this card can change in future having both match detail button and the date with league detail */}
            {data.status ? (
              <AppScorePill
                matchStatus="previous"
                valueFirst={data.teamA.goal}
                valueSecond={data.teamB.goal}
              />
            ) : (
              <AppScorePill
                matchStatus="live"
                valueFirst={data.teamA.goal}
                valueSecond={data.teamB.goal}
              />
            )}
          </AppBox>
          <AppBox gap="xs" justifyContent="start" alignItems="center">
            <AppAvatar username={data.teamB.name} src={data.teamB.logo} />
            <AppTitle truncate={true} as="h6">
              {data.teamB.name}
            </AppTitle>
          </AppBox>
        </AppGridBox>
        <AppBox justifyContent="end" alignItems="center">
          {data.status ? (
            <AppBorderBox border={["Left"]} pl="xs">
              <AppBox flexDirection="column" gap="3xs">
                <AppText as="label" size="sm" color="muted">
                  {data.date}
                </AppText>
                <AppText as="label" size="sm" color="muted">
                  {data.league}
                </AppText>
              </AppBox>
            </AppBorderBox>
          ) : (
            <AppButton
              size="lg"
              variant="light"
              label="Match Details"
            ></AppButton>
          )}
        </AppBox>
      </AppGridBox>
    </AppPaper>
  );
}
