import { useState } from "react";
import AppBox from "../Box";
import IconSearch from "../icons/search";
import IconDismiss from "../icons/dismiss";
interface SearchProps {
  placeholder?: string;
  onChange?: (value: string) => any;
}

export default function Search({
  placeholder = "Search",
  onChange,
}: SearchProps) {
  const [value, setValue] = useState("");
  const handleChange = (input: string) => {
    setValue(input);
    if (onChange) {
      onChange(input);
    }
  };

  return (
    <div className="ee-search-box">
      <input
        type="search"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        className="ee-input ee-input-search"
        placeholder={placeholder}
      />
      <div className="search-actions">
        {value ? (
          <span onClick={() => handleChange("")}>
            <IconDismiss />
          </span>
        ) : (
          <IconSearch />
        )}
      </div>
    </div>
  );
}
