import { useEffect, useState } from "react";
import {
  AppAvatar,
  AppBox,
  AppGridBox,
  AppText,
  AppTitle,
} from "../../commons/components";
import AppTabs, { TabItem } from "../../commons/components/Tabs";
import { useLocation } from "react-router-dom";
import NewsTimelineCard from "../../commons/components/ui-components/NewsTimelineCard";
import MatchLineupCard, { IPlayer } from "./components/MatchLineupCard";
import Nelson from "../../assets/images/players/Nelson.png";
import { matchInfo } from "./MatchDetail";
import { newsData } from "./MatchSummary";
import MatchTeamForm from "./components/MatchTeamForm";

export default function MatchLineup() {
  const [currentTab, setCurrentTab] = useState("all-commentary");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const location = useLocation();
  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  const tabItems = [
    {
      label: matchInfo.teamA.name,
      value: matchInfo.teamA.name,
      href: "team-1",
      icon: (
        <AppAvatar
          size="xs"
          username={matchInfo.teamA.name}
          src={matchInfo.teamA.logo}
        />
      ),
    },
    {
      label: matchInfo.teamB.name,
      value: matchInfo.teamA.name,
      href: "team-2",
      icon: (
        <AppAvatar
          size="xs"
          username={matchInfo.teamA.name}
          src={matchInfo.teamB.logo}
        />
      ),
    },
  ];

  const players: IPlayer[] = [
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 1,
      position: "GK",
      team: "Arsenal",
      role: "goalkeeper",
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 2,
      position: "LCB",
      team: "Arsenal",
      role: "defender",
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 3,
      position: "RCB",
      team: "Arsenal",
      role: "defender",
      event: [{ matchTime: ["85"], matchEvent: "SO" }],
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 4,
      position: "RWB",
      team: "Arsenal",
      role: "defender",
      event: [{ matchTime: ["73"], matchEvent: "SO" }],
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 5,
      position: "LWB",
      team: "Arsenal",
      role: "defender",
    },
    {
      id: 0,
      name: "Reiss",
      avatar: Nelson,
      number: 6,
      position: "RCM",
      team: "Arsenal",
      role: "midfielder",
      event: [{ matchTime: ["85"], matchEvent: "YC" }],
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 7,
      position: "CM",
      team: "Arsenal",
      role: "midfielder",
      event: [{ matchTime: ["86"], matchEvent: "SO" }],
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 8,
      position: "LCM",
      team: "Arsenal",
      role: "midfielder",
      event: [{ matchTime: ["18", "31"], matchEvent: "G" }],
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 9,
      position: "RW",
      team: "Arsenal",
      role: "forward",
      event: [{ matchTime: ["74"], matchEvent: "SO" }],
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 10,
      position: "LW",
      team: "Arsenal",
      role: "forward",
      event: [{ matchTime: ["86"], matchEvent: "G" }],
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 11,
      position: "CS",
      team: "Arsenal",
      role: "forward",
      event: [{ matchTime: ["59"], matchEvent: "SO" }],
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 12,
      position: "CS",
      team: "Arsenal",
      role: "subtitute",
      event: [{ matchTime: ["59"], matchEvent: "SI" }],
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 13,
      position: "CS",
      team: "Arsenal",
      role: "subtitute",
      event: [{ matchTime: ["73"], matchEvent: "SI" }],
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 14,
      position: "CS",
      team: "Arsenal",
      role: "subtitute",
      event: [{ matchTime: ["74"], matchEvent: "SI" }],
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 15,
      position: "CS",
      team: "Arsenal",
      role: "subtitute",
      event: [
        { matchTime: ["85"], matchEvent: "SI" },
        { matchTime: ["89"], matchEvent: "RC" },
      ],
    },
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 16,
      position: "CS",
      team: "Arsenal",
      role: "subtitute",
      event: [
        { matchTime: ["86"], matchEvent: "SI" },
        { matchTime: ["87"], matchEvent: "OG" },
        { matchTime: ["88"], matchEvent: "YC" },
        { matchTime: ["89"], matchEvent: "DYC" },
      ],
    },
  ];
  const goalkeeper = players.find((player) => player.role === "goalkeeper");
  const defenders = players.filter((player) => player.role === "defender");
  const midfielders = players.filter((player) => player.role === "midfielder");
  const forwards = players.filter((player) => player.role === "forward");
  const subtitutes = players.filter((player) => player.role === "subtitute");

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <AppBox displayBlock={true}>
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">{"Match Lineup"}</AppTitle>
          <AppBox>
            <AppTabs
              as="a"
              tabItemClassName="tab-big"
              onClick={(e) => setActiveItem(e)}
              activeValue={currentTab}
              tabItems={tabItems}
              variant="filled"
              shadow="xs"
            />
          </AppBox>
          <AppBox flexDirection="column" gap="sm">
            <MatchTeamForm team={players} />
          </AppBox>
          <AppBox flexDirection="column" gap="sm">
            <AppBox flexDirection="column" gap="xs">
              <AppText>Goalkeeper</AppText>
              <MatchLineupCard player={goalkeeper as IPlayer} />
            </AppBox>
            <AppBox flexDirection="column" gap="xs">
              <AppText>Defenders</AppText>
              <AppBox flexDirection="column" gap="sm">
                {defenders.map((defender, index) => (
                  <MatchLineupCard player={defender} key={index} />
                ))}
              </AppBox>
            </AppBox>
            <AppBox flexDirection="column" gap="xs">
              <AppText>Midfielders</AppText>
              <AppBox flexDirection="column" gap="sm">
                {midfielders.map((midfielder, index) => (
                  <MatchLineupCard player={midfielder} key={index} />
                ))}
              </AppBox>
            </AppBox>
            <AppBox flexDirection="column" gap="xs">
              <AppText>Forwards</AppText>
              <AppBox flexDirection="column" gap="sm">
                {forwards.map((forward, index) => (
                  <MatchLineupCard player={forward} key={index} />
                ))}
              </AppBox>
            </AppBox>
            <AppBox flexDirection="column" gap="xs">
              <AppText>Subtitutes</AppText>
              <AppBox flexDirection="column" gap="sm">
                {subtitutes.map((subtitute, index) => (
                  <MatchLineupCard player={subtitute} key={index} />
                ))}
              </AppBox>
            </AppBox>
          </AppBox>
        </AppBox>
      </AppBox>
      <NewsTimelineCard news={newsData} timelineType="small" />
    </AppGridBox>
  );
}
