import { AppAvatar, AppBox, AppPaper, AppText, AppTitle } from "../../../commons/components";
import HeaderBackGround from "../../../assets/images/backgrounds/Elements.svg";
import AppTabs, { TabItem } from "../../../commons/components/Tabs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ITournamentHeader } from "../../../commons/components/interface/i-tournament";

interface ITournamentDetailHeaderProps {
  tournamentData: ITournamentHeader;
}

export default function TournamentDetailHeader({ tournamentData }: ITournamentDetailHeaderProps) {
  const [currentTab, setCurrentTab] = useState("summary");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const location = useLocation();

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  const tabItems = [
    {
      label: "Summary",
      value: "summary",
      href: "summary",
    },
    {
      label: "Profile",
      value: "profile",
      href: "profile",
    },
    {
      label: "Teams",
      value: "teams",
      href: "teams",
    },
    {
      label: "Events",
      value: "events",
      href: "events",
    },
    {
      label: "Statistics",
      value: "statistics",
      href: "statistics",
    },
    {
      label: "Standings",
      value: "standings",
      href: "standings",
    },
    {
      label: "News",
      value: "news",
      href: "news",
    },
  ];
  return (
    <AppPaper shadow="xs" padding="none">
      <AppBox
        alignItems="center"
        gap="xs"
        p="sm"
        style={{
          backgroundImage: `url(${HeaderBackGround})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          borderBottom: "1px solid rgb(var(--border-default))",
        }}
      >
        <AppAvatar username={tournamentData.name} size="2xl" variant="outline" src={tournamentData.logo} />
        <AppBox flexDirection="column">
          <AppTitle as="h4">{tournamentData.name}</AppTitle>
          <AppText as="p" size="md">
            {tournamentData.year}
          </AppText>
        </AppBox>
      </AppBox>
      <AppBox p="sm">
        <AppTabs as="a" onClick={(e) => setActiveItem(e)} activeValue={currentTab} tabItems={tabItems} />
      </AppBox>
    </AppPaper>
  );
}
