import { AppBox } from "../../../commons/components";
import MatchCommentaryCard, { IMatchCommentary } from "./MatchCommentaryCard";

export default function MatchCommentaryKey() {
  const matchCommentary: IMatchCommentary[] = [
    {
      matchId: 1,
      status: "G",
      matchTime: "105+1'",
      label:
        "Attempt blocked. Bukayo Saka (Arsenal) left footed shot from the right side of the box is blocked. Assisted by Gabriel Jesus.",
    },
    {
      matchId: 1,
      status: "DYC",
      matchTime: "90+6'",
      label:
        "Mykhailo Mudryk (Chelsea) wins a free kick in the defensive half.",
    },
    {
      matchId: 1,
      status: "S",
      matchTime: "75'",
      label:
        "Attempt blocked. Bukayo Saka (Arsenal) left footed shot from the right side of the box is blocked. Assisted by Gabriel Jesus.",
    },
    {
      matchId: 1,
      status: "RC",
      matchTime: "45+2'",
      label:
        "Mykhailo Mudryk (Chelsea) wins a free kick in the defensive half.",
    },
    {
      matchId: 1,
      status: "YC",
      matchTime: "18'",
      label:
        "Mykhailo Mudryk (Chelsea) wins a free kick in the defensive half.",
    },
  ];
  return (
    <AppBox flexDirection="column" gap="sm" pl="xs">
      {matchCommentary.map((commentaryItem) => (
        <MatchCommentaryCard commentary={commentaryItem} />
      ))}
    </AppBox>
  );
}
