export default function IconWhistle() {
  return (
    <svg
      className="svg-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3876 4.89349V3H12.8876V4.89349H14.3876ZM11.801 5.30991L10.3809 3.88979L9.32026 4.95045L10.7404 6.37057L11.801 5.30991ZM16.5348 6.37057L17.9549 4.95045L16.8942 3.88979L15.4741 5.30991L16.5348 6.37057ZM2 14.3609C2 10.8095 4.87902 7.93047 8.43047 7.93047H11.7441C11.9948 7.93047 12.229 8.0558 12.3681 8.26445L13.0922 9.35059H14.1829L14.907 8.26445C15.0461 8.0558 15.2803 7.93047 15.5311 7.93047H21.6849C22.0991 7.93047 22.4349 8.26626 22.4349 8.68047V11.5207C22.4349 11.7196 22.3559 11.9104 22.2152 12.051L19.375 14.8913C19.2344 15.0319 19.0436 15.1109 18.8447 15.1109H14.8177C14.4462 18.309 11.7282 20.7914 8.43047 20.7914C4.87902 20.7914 2 17.9124 2 14.3609ZM8.43047 9.43047C5.70745 9.43047 3.5 11.6379 3.5 14.3609C3.5 17.084 5.70745 19.2914 8.43047 19.2914C11.1535 19.2914 13.3609 17.084 13.3609 14.3609C13.3609 13.9467 13.6967 13.6109 14.1109 13.6109H18.534L20.9349 11.2101V9.43047H15.9325L15.2084 10.5166C15.0693 10.7253 14.8351 10.8506 14.5843 10.8506H12.6908C12.4401 10.8506 12.2059 10.7253 12.0668 10.5166L11.3427 9.43047H8.43047ZM8.43046 13.2174C7.79893 13.2174 7.28697 13.7294 7.28697 14.3609C7.28697 14.9924 7.79893 15.5044 8.43046 15.5044C9.06199 15.5044 9.57395 14.9924 9.57395 14.3609C9.57395 13.7294 9.06199 13.2174 8.43046 13.2174ZM5.78697 14.3609C5.78697 12.9009 6.9705 11.7174 8.43046 11.7174C9.89042 11.7174 11.074 12.9009 11.074 14.3609C11.074 15.8209 9.89042 17.0044 8.43046 17.0044C6.9705 17.0044 5.78697 15.8209 5.78697 14.3609Z"
        fill="currentColor"
      />
    </svg>
  );
}
