import React from "react";
import {
  AppBox,
  AppGridBox,
  AppPaper,
  AppText,
  Jersey,
} from "../../../commons/components";
import { IPlayer, PlayerPositions } from "./MatchLineupCard";
import TeamFormBackGround from "../../../assets/images/backgrounds/ground.svg";

interface TeamFormProps {
  team: IPlayer[];
}

export default function MatchTeamForm({ team }: TeamFormProps) {
  const positions = [
    ["", "", "LB", "LWB", "LM", "LW", "", ""],
    ["", "", "LCB", "LDM", "LCM", "LAM", "LS", ""],
    ["GK", "SW", "CB", "CDM", "CM", "CAM", "CS", ""],
    ["", "", "RCB", "RDM", "RCM", "RAM", "RS", ""],
    ["", "", "RB", "RWB", "RM", "RW", "", ""],
  ];

  const getPlayerCountByPosition = (position: PlayerPositions): number => {
    switch (position) {
      case "GK":
      case "SW":
      case "CB":
      case "LCB":
      case "RCB":
      case "RB":
      case "LB":
      case "CDM":
      case "LDM":
      case "RDM":
      case "LWB":
      case "RWB":
      case "CM":
      case "LCM":
      case "RCM":
      case "LM":
      case "RM":
      case "CAM":
      case "LAM":
      case "RAM":
      case "RW":
      case "LW":
      case "CS":
      case "LS":
      case "RS":
        return team.filter(
          (player) =>
            player.position === position && player.role !== "subtitute"
        ).length;
      default:
        return 0;
    }
  };

  return (
    <AppBox flexDirection="column" gap="xs">
      <AppText>{`${
        team.filter((player) => player.role === "defender").length
      }-${team.filter((player) => player.role === "midfielder").length}-${
        team.filter((player) => player.role === "forward").length
      }`}</AppText>
      <AppPaper padding="none" shadow="xs" style={{ position: "relative" }}>
        <img
          alt="ground img"
          src={TeamFormBackGround}
          style={{ width: "100%" }}
        />
        <AppGridBox
          columns={8}
          rows={5}
          p="2xl"
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          {positions.map((row, rowIndex) =>
            row.map((position, colIndex) => {
              const playerPosition = positions[rowIndex][colIndex];
              const player = team.find(
                (player) =>
                  player.position === playerPosition &&
                  player.role !== "subtitute"
              );
              return (
                <AppBox
                  key={colIndex}
                  alignItems="center"
                  justifyContent="center"
                >
                  {Array.from({
                    length: getPlayerCountByPosition(
                      position as PlayerPositions
                    ),
                  }).map((_, index) => {
                    return (
                      <React.Fragment key={index}>
                        {player && (
                          <>
                            {player.position === "GK" ? (
                              <Jersey
                                colorBack="#1F1D1C"
                                colorText="white"
                                colorSleeve="#171514"
                                name={player.name}
                                number={player.number}
                                size={80}
                              />
                            ) : (
                              <Jersey
                                colorBack="#E4450A"
                                colorText="white"
                                colorSleeve="#B23608"
                                name={player.name}
                                number={player.number}
                                size={80}
                              />
                            )}
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </AppBox>
              );
            })
          )}
        </AppGridBox>
      </AppPaper>
    </AppBox>
  );
}
