import { useEffect, useState } from "react";
import { AppAvatar, AppBox, AppText } from "../../../commons/components";
import { TextColors } from "../../../commons/types/core-types";

interface StandingTableRowProps {
  rowItems: ITableRowItems;
  index: number;
  isActive?: boolean;
  activeColor?: string;
}

interface ITableRowItems {
  id: number;
  name: string;
  avatar: string;
  gp: number;
  w: number;
  d: number;
  l: number;
  gf: number;
  ga: number;
}

export default function StandingTableRow({
  rowItems,
  index,
  isActive,
  activeColor = "accent",
}: StandingTableRowProps) {
  const [goalDifference, setGdValue] = useState<{
    color: TextColors;
    value: string;
  }>({
    color: "default",
    value: "",
  });

  useEffect(() => {
    getGoalDiff();
  }, []);

  const getGoalDiff = () => {
    const diff = rowItems.gf - rowItems.ga;
    if (diff > 0) {
      setGdValue({ color: "success", value: "+" + diff });
    } else if (diff < 0) {
      setGdValue({ color: "danger", value: diff.toString() });
    } else {
      setGdValue({ color: "default", value: diff.toString() });
    }
  };

  const getTotalPoint = () => {
    return rowItems.w * 3 + rowItems.d;
  };

  return (
    <tr
      className={`table-row ${isActive ? "active-row" : ""} ${
        activeColor && "color-" + activeColor
      }`}
    >
      <td>
        <AppBox className="row-left-section" gap="2xs" alignItems="center">
          <AppText fontWeight="semibold" as="label" className="index">
            {index + 1}
          </AppText>
          <AppAvatar
            username={rowItems.name}
            size="xs"
            variant="outline"
            color="gray"
            src={rowItems.avatar}
          />
          <AppText fontWeight="semibold" lineClamp={1} as="label">
            {rowItems.name}
          </AppText>
        </AppBox>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium">{rowItems.gp}</AppText>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium">{rowItems.w}</AppText>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium">{rowItems.d}</AppText>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium">{rowItems.l}</AppText>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium">
          {rowItems.gf}:{rowItems.ga}
        </AppText>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium" color={goalDifference.color}>
          {goalDifference.value}
        </AppText>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium">{getTotalPoint()}</AppText>
      </td>
    </tr>
  );
}
