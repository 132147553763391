import AppBox from "../Box";
import AppText from "../Text";

interface JerseyProps {
  name: string;
  number: number;
  colorBack: string;
  colorSleeve: string;
  colorText: string;
  size: number;
}

export default function Jersey({
  name,
  number,
  colorBack,
  colorSleeve,
  colorText,
  size = 80,
}: JerseyProps) {
  return (
    <>
      <AppBox
        flexDirection="column"
        alignItems="center"
        style={{
          position: "relative",
          height: size,
          width: size,
        }}
      >
        <svg fill="none" viewBox="0 0 56 56">
          <path
            fill={colorBack}
            d="M47.405 3.148V56H8.595V3.148L19.622 0C22.159 1.23 25 1.917 28 1.917S33.841 1.23 36.378 0l11.027 3.148Z"
          />
          <path
            fill={colorSleeve}
            d="M47.404 3.148 56 20.912s-2.692 5.472-8.596 4.363V3.148Zm-38.809 0L0 20.912s2.691 5.472 8.595 4.363V3.148Z"
          />
        </svg>
        <AppBox
          alignItems="center"
          style={{
            position: "absolute",
            margin: "0",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <AppText
            style={{
              fontSize: 32,
              fontWeight: "bold",
              color: colorText,
            }}
          >
            {number}
          </AppText>
        </AppBox>
        <AppBox
          alignItems="center"
          style={{
            position: "absolute",
            margin: "0",
            top: "100%",
            whiteSpace: "nowrap",
          }}
        >
          <AppText
            style={{
              color: "white",
            }}
          >
            {name}
          </AppText>
        </AppBox>
      </AppBox>
    </>
  );
}
