import React from "react";
import { Colors, Sizes } from "../types/core-types";

export type ActionIconVariant =
  | "default"
  | "filled"
  | "outline"
  | "light"
  | "subtle";
export type ActionIconSize = Sizes;
export type ActionIconRadius = Sizes;
export type ActionIconColor = Colors;

export interface ActionIconProps {
  ariaLabel?: string;
  variant?: ActionIconVariant;
  size?: ActionIconSize;
  color?: ActionIconColor;
  radius?: ActionIconRadius;
  icon: React.ReactNode;
  className?: string;
  style?: object;
}

export default function AppActionIcon({
  ariaLabel,
  variant = "light",
  size = "md",
  color = "primary",
  radius = "md",
  icon,
  className,
  style,
}: ActionIconProps) {
  const classNames = [
    `ee-action-icon`,
    variant && `bg-${variant}`,
    size && `size-${size}`,
    radius && `radius-${radius}`,
    color && `color-${color}`,
    className,
  ]
    .filter(Boolean)
    .join(" ");
  return (
    <div
      style={style}
      data-variant={variant}
      className={classNames}
      aria-label={ariaLabel}>
      {icon}
    </div>
  );
}
