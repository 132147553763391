import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppText,
  AppTitle,
} from "../../commons/components";
import UpcomingMatchCard from "./components/UpcomingMatchCard";
import NewCastleLogo from "../../assets/images/clubs/Newcastel.png";
import Hampton from "../../assets/images/clubs/WestHam.png";
import Brighton from "../../assets/images/clubs/Brighton.png";
import Arsenal from "../../assets/images/clubs/Arsenal.png";
import Nottingham from "../../assets/images/clubs/Nottingham.png";
import Liverpool from "../../assets/images/clubs/Liverpool.png";
import UpcomingTraining from "./components/UpcomingTrainingCard";
import Football from "../../assets/images/clubs/Football.png";
import MatchResultCard from "./components/MatchResultCard";
import NewsFIrstImage from "../../assets/images/news/image 130.png";
import NewsSecondImage from "../../assets/images/news/Image Block.png";
import NewsThirdImage from "../../assets/images/news/Image.png";
import NewsFourthImage from "../../assets/images/news/Player.png";
import IconCalendar from "../../commons/components/icons/calendar";
import NewsTimelineCard from "../../commons/components/ui-components/NewsTimelineCard";
export default function TeamEvents() {
  const upcomingMatch = {
    date: "28 May, 2023",
    league: "English Premier League",
    id: 1,
    teamB: {
      name: "New Castle",
      logo: NewCastleLogo,
    },
    teamA: {
      name: "Arsenal",
      logo: Arsenal,
    },
  };

  const traniningInfo = {
    logo: Football,
    name: "Footbal",
    description: "Strength Training followed by Cardio & Stretching",
    date: "12:45-19:45",
    location: "Emirates Stadium, London",
  };

  const matchResult = [
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      status: "W",
      teamB: {
        name: "W. Hampton",
        logo: Hampton,
        goal: 0,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 5,
      },
    },
    {
      id: 2,
      date: "29 May, 2023",
      league: "English Premier League",
      status: "L",
      teamA: {
        name: "Nottingham",
        logo: Nottingham,
        goal: 1,
      },
      teamB: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 0,
      },
    },
    {
      id: 3,
      date: "01 May, 2023",
      league: "English Premier League",
      status: "L",
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 1,
      },
      teamB: {
        name: "Brighton",
        logo: Brighton,
        goal: 2,
      },
    },
    {
      id: 4,
      date: "02 May, 2023",
      league: "English Premier League",
      status: "W",
      teamA: {
        name: "Liverpool",
        logo: Liverpool,
        goal: 1,
      },
      teamB: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 2,
      },
    },
  ];

  const news = [
    {
      id: 1,
      date: new Date("2023/06/20"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFIrstImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFourthImage} alt="" />,
        },
      ],
    },
    {
      id: 2,
      date: new Date("2023/06/22"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecondImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
      ],
    },
  ];

  const selectOptions = ["Premier League", "Laliga", "league 1"];

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox displayBlock={true}>
        <AppBox flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="sm">
            <AppBox flexDirection="column" gap="xs">
              <AppBox justifyContent="space-between">
                <AppTitle as="h5">{"Matches & Result"}</AppTitle>
                <IconCalendar />
              </AppBox>
              <AppSelect options={selectOptions} className="w-1/2" />
            </AppBox>
            <AppBox flexDirection="column" gap="2xs">
              <AppText>{"Saturday 19, August 2023"}</AppText>
              <UpcomingMatchCard data={upcomingMatch} />
            </AppBox>
          </AppBox>

          <AppBox flexDirection="column" gap="xs">
            <AppBox justifyContent="space-between" alignItems="center">
              <AppTitle as="h5">{"Upcoming Training"}</AppTitle>
              <IconCalendar />
            </AppBox>

            <AppBox flexDirection="column" gap="2xs">
              <AppText>{"Saturday 19, August 2023"}</AppText>
              <UpcomingTraining trainingData={traniningInfo} />
            </AppBox>
          </AppBox>

          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">{"Form (Last 5 Matches"}</AppTitle>
            <AppBox flexDirection="column" gap="sm" pl="xs">
              {matchResult.map((result) => (
                <MatchResultCard key={result.id} data={result} />
              ))}
            </AppBox>
          </AppBox>
        </AppBox>
      </AppBox>
      <AppBox displayBlock>
        <NewsTimelineCard news={news} timelineType="small" />
      </AppBox>
    </AppGridBox>
  );
}
