import { CSSProperties } from "react";
import {
  Colors,
  Orientation,
  Sizes,
  Spaces,
  SpacingOptions,
} from "../types/core-types";

type BorderColorOptions =
  | "default"
  | "dark"
  | "primary"
  | "accent"
  | "info"
  | "success"
  | "warning"
  | "danger";

interface DividerProps extends SpacingOptions {
  color?: BorderColorOptions;
  label?: string;
  margin?: Spaces | "none";
  orientation?: Orientation;
  size?: Sizes;
  variant?: CSSProperties["borderTopStyle"] | CSSProperties["borderLeftStyle"];
}

export default function AppDivider({
  color,
  variant = "solid",
  orientation = "horizontal",
  size = "xs",
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
  label,
}: DividerProps) {
  const mergedStyles =
    orientation === "horizontal"
      ? {
          borderTopStyle: variant,
          borderTopWidth: size && `var(--divider-${size})`,
        }
      : {
          borderLeftStyle: variant,
          borderLeftWidth: size && `var(--divider-${size})`,
        };
  const classNames = [
    `divider`,
    p && `p-${p}`,
    pt && `pt-${pt}`,
    pr && `pr-${pr}`,
    pb && `pb-${pb}`,
    pl && `pl-${pl}`,
    px && `px-${px}`,
    py && `py-${py}`,
    m && `m-${m}`,
    mt && `mt-${mt}`,
    mr && `mr-${mr}`,
    mb && `mb-${mb}`,
    ml && `ml-${ml}`,
    mx && `mx-${mx}`,
    my && `my-${my}`,
    color && `border-${color}`,
  ]
    .filter(Boolean)
    .join(" ");
  return (
    <div
      className={classNames}
      style={mergedStyles}
      data-orientation={orientation}></div>
  );
}
