import {
  AppBox,
  AppButton,
  AppGridBox,
  AppText,
  AppTitle,
} from "../../commons/components";
import Arsenal from "../../assets/images/clubs/Arsenal.png";
import Odegard from "../../assets/images/players/Odegaard.png";
import NewCastleLogo from "../../assets/images/clubs/Newcastel.png";
import Nelson from "../../assets/images/players/Nelson.png";
import UpcomingMatchCard from "./components/UpcomingMatchCard";
import MatchResultCard from "./components/MatchResultCard";
import FeaturedPlayerCard from "./components/FeaturedPlayerCard";
import TeamStatisticsCard from "./components/TeamStatisticsCard";
import TeamStandingTable from "./components/TeamStandingTable";
import IconInfo from "../../commons/components/icons/info";
import NewsTimelineCard from "../../commons/components/ui-components/NewsTimelineCard";
import NewsFIrstImage from "../../assets/images/news/image 130.png";
import NewsSecondImage from "../../assets/images/news/Image Block.png";
import TeamImage from "../../assets/images/news/team.png";
import NewsSecond from "../../assets/images/news/ProfileNews1.png";
import NewsThird from "../../assets/images/news/ProfileNews2.png";
import NewsFourth from "../../assets/images/news/ProfileNews3.png";

export default function TeamSummary() {
  const playerInfo = [
    {
      id: 1,
      name: "Martin Odegaard",
      avatar: Odegard,
      number: 8,
      position: "Midfielder",
      appearance: 1000,
      assist: 100,
      goal: 200,
      shot: 300,
    },
    {
      id: 2,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 24,
      position: "Forward",
      appearance: 1200,
      assist: 300,
      goal: 500,
      shot: 300,
    },
  ];

  const upcomingMatch = {
    date: "28 May, 2023",
    league: "English Premier League",
    id: 1,
    teamB: {
      name: "New Castle",
      logo: NewCastleLogo,
    },
    teamA: {
      name: "Arsenal",
      logo: Arsenal,
    },
  };

  const teamStats = {
    matchPlayed: 1000,
    goal: 800,
    gaolConceded: 200,
    cleanSheet: 20,
    win: 700,
    draw: 100,
    loss: 200,
  };

  const matchResult = {
    teamA: {
      name: "New Castle",
      logo: NewCastleLogo,
      goal: 2,
    },
    teamB: {
      name: "Arsenal",
      logo: Arsenal,
      goal: 4,
    },
  };

  const news = [
    {
      id: 1,
      date: new Date("2023/06/20"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton. He was really good and I remember a few teams looking at him, he was a really good player.",
          media: <img src={NewsFIrstImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecondImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={TeamImage} alt="" />,
        },
      ],
    },
    {
      id: 2,
      date: new Date("2023/06/22"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecond} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThird} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFourth} alt="" />,
        },
      ],
    },
  ];

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox displayBlock>
        <AppBox flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">{"Upcoming Match"}</AppTitle>
            <AppBox flexDirection="column" gap="2xs">
              <AppText>{"Saturday 19, August 2023"}</AppText>
              <UpcomingMatchCard data={upcomingMatch} />
            </AppBox>
          </AppBox>

          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">{"Previous Match"}</AppTitle>
            <AppBox flexDirection="column" gap="2xs">
              <AppText>{"Saturday 19, August 2023"}</AppText>
              <MatchResultCard data={matchResult} />
            </AppBox>
          </AppBox>

          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">{"Team Statistics"}</AppTitle>
            <AppBox flexDirection="column" gap="sm">
              <TeamStatisticsCard data={teamStats} />
              <AppBox className="w-1/2 mx-auto" flexDirection="column">
                <AppButton
                  fullWidth
                  label="View Detail Stats"
                  variant="light"
                />
              </AppBox>
            </AppBox>
          </AppBox>
          <AppBox flexDirection="column" gap="xs">
            <AppBox justifyContent="space-between">
              <AppTitle as="h5">Team Standings</AppTitle>
              <IconInfo />
            </AppBox>
            <AppBox gap="sm" flexDirection="column">
              <TeamStandingTable
                activeValue={[1, 3, 7]}
                activeColors={["primary", "accent", "info"]}
              />
              <AppBox className="w-1/2 mx-auto" flexDirection="column">
                <AppButton fullWidth label="View Full Table" variant="light" />
              </AppBox>
            </AppBox>
          </AppBox>
          <NewsTimelineCard news={news} timelineType="big" />
        </AppBox>
      </AppBox>
      <AppBox flexDirection="column" gap="xs">
        <AppTitle as="h5">Featured Players</AppTitle>
        {playerInfo.map((item) => (
          <FeaturedPlayerCard key={item.id} playerInfo={item} />
        ))}
      </AppBox>
    </AppGridBox>
  );
}
