import { AppAvatar, AppBox, AppDivider, AppPaper, AppText, AppTitle } from "../../../commons/components";

export interface IPlayerStat {
  name: string;
  avatar: string;
  team: string;
  position: string;
  number?: number;
  statValue: number;
}

interface TopStatsProps {
  player: IPlayerStat;
  rank: number;
}
export default function TopStatsCard({ player, rank }: TopStatsProps) {
  const formattedRank = rank.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
  return (
    <AppPaper radius="md" padding="xs" shadow="xs">
      <AppBox alignItems="center" gap="xs">
        <AppText as="label" size="sm" color="muted" className="min-w-2">
          {formattedRank}
        </AppText>
        <AppAvatar username={player.name} src={player.avatar} />
        <AppBox alignItems="center" gap="xs" className="flex-1">
          <AppTitle as={"h5"}>{player.name}</AppTitle>
          <AppBox>
            <AppText as="label" size="sm" color="muted">
              {player.team}
            </AppText>
            <AppDivider color="dark" orientation="vertical" mx="3xs" />
            <AppText as="label" size="sm" color="muted">
              {player.position}
            </AppText>
          </AppBox>
        </AppBox>
        <AppTitle as={"h5"} color="primary">
          {player.statValue}
        </AppTitle>
      </AppBox>
    </AppPaper>
  );
}
