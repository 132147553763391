import React, { useEffect, useState } from "react";
import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppTitle,
} from "../../commons/components";
import NewsTimelineCard from "../../commons/components/ui-components/NewsTimelineCard";
import NewsFIrstImage from "../../assets/images/news/image 130.png";
import NewsSecondImage from "../../assets/images/news/Image Block.png";
import NewsThirdImage from "../../assets/images/news/Image.png";
import NewsFourthImage from "../../assets/images/news/Player.png";
import TeamStatisticsCard from "./components/TeamStatisticsCard";
import AppTabs from "../../commons/components/Tabs";
import { Outlet, useLocation } from "react-router-dom";

export default function TeamStatistics() {
  const [activeTab, setActiveTab] = useState("defebnce");
  const location = useLocation();
  useEffect(() => {
    const url = location.pathname.split("/");
    setActiveTab(url[url.length - 1]);
  }, [activeTab, location.pathname]);

  const news = [
    {
      id: 1,
      date: new Date("2023/06/20"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFIrstImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFourthImage} alt="" />,
        },
      ],
    },
    {
      id: 2,
      date: new Date("2023/07/22"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecondImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThirdImage} alt="" />,
        },
      ],
    },
  ];
  const leagues = ["Premier League", "Laliga", "League 1", "FA Cup"];
  const Season = ["2022/23", "2021/22", "2020/21"];

  const teamStats = {
    matchPlayed: 1000,
    goal: 800,
    gaolConceded: 200,
    cleanSheet: 20,
    win: 700,
    draw: 100,
    loss: 200,
  };

  const tabItems = [
    {
      label: "Attack",
      value: "attack",
      href: "attack",
    },
    {
      label: "Team Play",
      value: "team-play",
      href: "team-play",
    },
    {
      label: "Defence",
      value: "defence",
      href: "defence",
    },
    {
      label: "Discipline",
      value: "discipline",
      href: "discipline",
    },
  ];

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox flexDirection="column" gap="sm">
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">Team Statistics</AppTitle>
          <AppGridBox columns={2} gap="sm">
            <AppSelect options={leagues} />
            <AppSelect options={Season} />
          </AppGridBox>
        </AppBox>
        <AppBox flexDirection="column" gap="md">
          <TeamStatisticsCard data={teamStats} />
          <AppBox flexDirection="column" gap="xs">
            <AppBox>
              <AppTabs
                activeValue={activeTab}
                variant="filled"
                tabItems={tabItems}
              />
            </AppBox>
            <Outlet />
          </AppBox>
        </AppBox>
      </AppBox>
      <AppBox displayBlock>
        <NewsTimelineCard news={news} timelineType="small" />
      </AppBox>
    </AppGridBox>
  );
}
