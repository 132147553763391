import { useState } from "react";
import { AppBox, AppContainer, AppGridBox, AppTitle } from "../../commons/components";
import AppTabs, { TabItem } from "../../commons/components/Tabs";
import Odegard from "../../assets/images/players/Odegaard.png";
import Jesus from "../../assets/images/players/Jesus.png";
import Nelson from "../../assets/images/players/Nelson.png";
import NewCastleLogo from "../../assets/images/clubs/Newcastel.png";
import Arsenal from "../../assets/images/clubs/Arsenal.png";
import LiveMatchCard from "../team-detail/components/LiveMatchCard";
import UpcomingMatchCard from "../team-detail/components/UpcomingMatchCard";
import NewsTimelineCard, { INewsItem } from "../../commons/components/ui-components/NewsTimelineCard";
import TopStatsGroup from "./components/TopStatsGroup";

export default function Matches() {
  const [currentTab, setCurrentTab] = useState("premiere-league");
  const handleTabCLick = (data: TabItem) => {
    setCurrentTab(data.value);
  };

  const tabItems = [
    {
      label: "Premiere League",
      value: "premiere-league",
      href: "premiere-league",
    },
    {
      label: "Emirates FA Cup",
      value: "emirates-fa-cup",
      href: "emirates-fa-cup",
    },
    {
      label: "Europa League",
      value: "europa-league",
      href: "europa-league",
    },
    {
      label: "Champions League",
      value: "champions-league",
      href: "champions-league",
    },
    {
      label: "EFL",
      value: "efl",
      href: "efl",
    },
  ];

  const playerStats = [
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 24,
      position: "Forward",
      team: "Arsenal",
      statValue: 500,
    },
    {
      id: 1,
      name: "Martin Odegaard",
      avatar: Odegard,
      number: 8,
      position: "Midfielder",
      team: "Arsenal",
      statValue: 300,
    },
    {
      id: 2,
      name: "Gabriel Jesus",
      avatar: Jesus,
      number: 9,
      position: "Forward",
      team: "Arsenal",
      statValue: 48,
    },
    {
      id: 3,
      name: "Gabriel Jesus",
      avatar: Jesus,
      number: 9,
      position: "Forward",
      team: "Arsenal",
      statValue: 48,
    },
    {
      id: 4,
      name: "Gabriel Jesus",
      avatar: Jesus,
      number: 9,
      position: "Forward",
      team: "Arsenal",
      statValue: 48,
    },
  ];

  const upcomingMatch = [
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
  ];

  const liveMatch = [
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      time: "90+4",
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
        goal: 1,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 2,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      time: "90+4",
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
        goal: 2,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 1,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      time: "90+4",
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
        goal: 1,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 1,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      time: "90+4",
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
        goal: 0,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
        goal: 0,
      },
    },
  ];

  const newsData: INewsItem[] = [
    {
      id: 0,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
      ],
    },
    {
      id: 1,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
      ],
    },
  ];

  return (
    <AppBox flexDirection="column" gap="md" py="md">
      <AppContainer>
        <AppBox flexDirection="column" gap="md">
          <AppBox>
            <AppTabs as="a" onClick={handleTabCLick} activeValue={currentTab} tabItems={tabItems} />
          </AppBox>
          <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
            <AppBox flexDirection="column" gap="md">
              <AppBox flexDirection="column" gap="xs">
                <AppTitle as="h5">{"Live Matches"}</AppTitle>
                <AppBox flexDirection="column" gap="sm">
                  {liveMatch.map((match, index) => (
                    <LiveMatchCard key={index} data={match} />
                  ))}
                </AppBox>
              </AppBox>
              <AppBox flexDirection="column" gap="xs">
                <AppTitle as="h5">{"Upcoming Match"}</AppTitle>
                <AppBox flexDirection="column" gap="sm">
                  {upcomingMatch.map((match, index) => (
                    <UpcomingMatchCard key={index} data={match}/>
                  ))}
                </AppBox>
              </AppBox>
              <TopStatsGroup statsType="Goal" stats={playerStats} />
            </AppBox>
            <NewsTimelineCard news={newsData} timelineType="small"/>
          </AppGridBox>
        </AppBox>
      </AppContainer>
    </AppBox>
  );
}
