import { configureStore } from "@reduxjs/toolkit";
import { authReducer, sessionReducer } from "./slices";
const store = configureStore({
    reducer: {
       session: sessionReducer,
       auth: authReducer,
    },
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({ serializableCheck: false });
    },
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>

export default store;