import React, { ReactNode } from "react";
import { AppBox, AppGridBox, AppPaper, AppText, AppTitle } from "..";
import { TextColors } from "../../types/core-types";

type CardOrientation = "horizontal" | "vertical";

export interface NewsCardProps {
  orientation?: CardOrientation;
  previewChildren: React.ReactNode;
  title: string;
  label: string;
  titleColor?: TextColors;
}

const wrapWithClass = (child: ReactNode): ReactNode => {
  if (React.isValidElement(child)) {
    return React.cloneElement(child as React.ReactElement<any>, {
      style: {
        ...(child as React.ReactElement<any>).props.style,
        width: "100%",
      },
    });
  }
  return child;
};

export default function NewsCard({
  orientation = "vertical",
  previewChildren,
  title,
  label,
  titleColor,
}: NewsCardProps) {
  const wrappedPreview = wrapWithClass(previewChildren);
  return (
    <AppPaper
      style={{ overflow: "hidden", width: "100%" }}
      shadow="xs"
      padding="none"
      radius="md"
    >
      <AppBox
        flexDirection={orientation === "vertical" ? "column" : "row"}
        alignItems="left"
      >
        <AppGridBox
          className={orientation === "horizontal" ? "news-card-min" : ""}
          style={
            orientation === "horizontal"
              ? { gridTemplateColumns: "1fr 2fr" }
              : {}
          }
        >
          <AppBox>{wrappedPreview}</AppBox>
          <AppBox flexDirection="column" p="xs">
            <AppGridBox gap="3xs">
              <AppTitle
                size="md"
                color={titleColor}
                textAlign="left"
                lineClamp={2}
              >
                {title}
              </AppTitle>
              <AppText as="p" size="sm" color="muted" lineClamp={2}>
                {label}
              </AppText>
            </AppGridBox>
          </AppBox>
        </AppGridBox>
      </AppBox>
    </AppPaper>
  );
}
