import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppTitle,
} from "../../commons/components";
import Aaron from "../../assets/images/players/Ramsdale.png";
import Nelson from "../../assets/images/players/Nelson.png";
import Arsenal from "../../assets/images/clubs/Arsenal.png";

import FaCup from "../../assets/images/leagues/FACup.png";
import PremierLeague from "../../assets/images/leagues/PremierLeague.jpg";
import Europa from "../../assets/images/leagues/Europa.png";
import EFL from "../../assets/images/leagues/EFL.png";
import CHanpionLeague from "../../assets/images/leagues/ChanpionLeague.png";
import BestPlayerCard from "./components/BestPlayerCard";
import NewsTimelineCard from "../../commons/components/ui-components/NewsTimelineCard";
import BasicInfoCard from "./components/BasicInfoCard";
import NewsFIrstImage from "../../assets/images/news/image 130.png";
import NewsSecondImage from "../../assets/images/news/Image Block.png";
import TeamImage from "../../assets/images/news/team.png";
import NewsSecond from "../../assets/images/news/ProfileNews1.png";
import NewsThird from "../../assets/images/news/ProfileNews2.png";
import NewsFourth from "../../assets/images/news/ProfileNews3.png";
import AssociatedTeamOrLeague from "./components/AssociatedTeamOrLeague";

export default function TeamProfile() {
  const playerOfTheGame = [
    {
      id: 1,
      name: "Aaron Ramsdale",
      role: "Goalkeeper",
      avatar: Aaron,
      clubLogo: Arsenal,
      date: "30 Jan, 2023",
      opponent: "Bournemouth",
      awardTitle: "Save of the Season",
    },
    {
      id: 2,
      name: "Reiss Nelson",
      role: "Forward",
      avatar: Nelson,
      clubLogo: Arsenal,
      date: "30 Jan, 2023",
      opponent: "Bournemouth",
      awardTitle: "Game Changer",
    },
  ];

  const leagues = [
    {
      id: 1,
      logo: EFL,
    },
    {
      id: 2,
      logo: CHanpionLeague,
    },
    {
      id: 3,
      logo: PremierLeague,
    },
    {
      id: 4,
      logo: Europa,
    },
    {
      id: 5,
      logo: FaCup,
    },
  ];

  const news = [
    {
      id: 1,
      date: new Date("2023/06/20"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton. He was really good and I remember a few teams looking at him, he was a really good player.",
          media: <img src={NewsFIrstImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecondImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={TeamImage} alt="" />,
        },
      ],
    },
    {
      id: 2,
      date: new Date("2023/06/22"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecond} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThird} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFourth} alt="" />,
        },
      ],
    },
  ];

  const selectOptions = ["2023/24", "2022/23", "2021/22"];

  const info = {
    totalPlayer: 100,
    foundation: "1 Dec, 1886",
    description:
      "Arsenal floated between a handful of different pitches in the Plumstead area in their early years. They started life on Plumstead Common but, for their second season, Royal Arsenal rented the nearby Sportsman Ground on Plumstead Marshes. It was here that the Club would enter the London Senior Cup for the first time. Although they were beaten by Barnes in Round Two the wheels were in motion. With increasing support, the Club rented the Manor Ground the following season.",
  };

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox flexDirection="column" gap="md">
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">Basic Information</AppTitle>
          <BasicInfoCard basicInfo={info} />
        </AppBox>
        <NewsTimelineCard news={news} timelineType="big" />
      </AppBox>
      <AppBox flexDirection="column" gap="md">
        <AppBox flexDirection="column" gap="sm">
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">{"Awards & Trophies"}</AppTitle>
            <AppSelect options={selectOptions}></AppSelect>
          </AppBox>
          {playerOfTheGame.map((item) => (
            <BestPlayerCard key={item.id} playerInfo={item} />
          ))}
        </AppBox>
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">{"Associated Leagues"}</AppTitle>
          <AppBox flexWrap="wrap" gap="xs">
            {leagues.map((item) => (
              <AssociatedTeamOrLeague key={item.id} logo={item.logo} />
            ))}
          </AppBox>
        </AppBox>
      </AppBox>
    </AppGridBox>
  );
}
