import { AppAvatar, AppBox, AppContainer, AppSearch, AppSelect } from "..";
import ArtetaAvatar from "../../../assets/images/players/Coach.png";

export default function Toolbar() {
  const handleChange = (data: string) => {
    console.log(data);
  };

  const selectOptions = ["Football", "Cricket", "Volleyball"];
  return (
    <header className="ee-toolbar">
      <AppContainer>
        <AppBox className="left-section" gap="sm" alignItems="center"></AppBox>
        <AppBox className="right-section" gap="sm" alignItems="center">
          <AppSearch onChange={handleChange} />
          <AppSelect options={selectOptions} />
          <AppAvatar size="md" username={"Mikel Arteta"} src={ArtetaAvatar} />
        </AppBox>
      </AppContainer>
    </header>
  );
}
