import {
  AppAvatar,
  AppBox,
  AppGridBox,
  AppTitle,
} from "../../commons/components";
import NewsTimelineCard, {
  INewsItem,
} from "../../commons/components/ui-components/NewsTimelineCard";
import MatchStatisticsGroup from "./components/MatchStatisticsGroup";
import Arsenal from "../../assets/images/clubs/Arsenal.png";
import Chelsea from "../../assets/images/clubs/Chelsea.png";
import AppTabs, { TabItem } from "../../commons/components/Tabs";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import MatchResultCard from "../team-detail/components/MatchResultCard";

const newsData: INewsItem[] = [
  {
    id: 0,
    date: new Date(),
    news: [
      {
        title: "This is a news card with vertical content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: (
          <img
            alt=""
            src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
          />
        ),
      },
      {
        title: "This is a news card with horizontal content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: (
          <img
            alt=""
            src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
          />
        ),
      },
      {
        title: "This is a news card with horizontal content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: (
          <img
            alt=""
            src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
          />
        ),
      },
    ],
  },
  {
    id: 1,
    date: new Date(),
    news: [
      {
        title: "This is a news card with vertical content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: (
          <img
            alt=""
            src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
          />
        ),
      },
      {
        title: "This is a news card with horizontal content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: (
          <img
            alt=""
            src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
          />
        ),
      },
      {
        title: "This is a news card with horizontal content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: (
          <img
            alt=""
            src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
          />
        ),
      },
    ],
  },
];

const HtHResult = [
  {
    date: "28 May, 2023",
    league: "English Premier League",
    id: 1,
    teamB: {
      name: "Chelsea",
      logo: Chelsea,
      goal: 0,
    },
    teamA: {
      name: "Arsenal",
      logo: Arsenal,
      goal: 5,
    },
  },
  {
    id: 2,
    date: "29 May, 2023",
    league: "English Premier League",
    teamA: {
      name: "Chelsea",
      logo: Chelsea,
      goal: 1,
    },
    teamB: {
      name: "Arsenal",
      logo: Arsenal,
      goal: 0,
    },
  },
  {
    id: 3,
    date: "01 May, 2023",
    league: "English Premier League",
    teamA: {
      name: "Arsenal",
      logo: Arsenal,
      goal: 1,
    },
    teamB: {
      name: "Chelsea",
      logo: Chelsea,
      goal: 2,
    },
  },
  {
    id: 4,
    date: "02 May, 2023",
    league: "English Premier League",
    teamA: {
      name: "Chelsea",
      logo: Chelsea,
      goal: 1,
    },
    teamB: {
      name: "Arsenal",
      logo: Arsenal,
      goal: 2,
    },
  },
  {
    id: 5,
    date: "01 May, 2023",
    league: "English Premier League",
    teamA: {
      name: "Chelsea",
      logo: Chelsea,
      goal: 3,
    },
    teamB: {
      name: "Arsenal",
      logo: Arsenal,
      goal: 2,
    },
  },
];

export const matchInfo = {
  date: new Date() as unknown as string,
  league: "English Premiere League",
  teamA: {
    logo: Arsenal,
    name: "Arsenal",
  },
  teamB: {
    logo: Chelsea,
    name: "Chelsea",
  },
};
export default function MatchStatistics() {
  const [currentTab, setCurrentTab] = useState("summary");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const location = useLocation();

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  const tabItems = [
    {
      label: matchInfo.teamA.name,
      value: matchInfo.teamA.name,
      href: "team-1",
      icon: (
        <AppAvatar size="xs" username={matchInfo.teamA.name} src={Arsenal} />
      ),
    },
    {
      label: matchInfo.teamB.name,
      value: matchInfo.teamA.name,
      href: "team-2",
      icon: (
        <AppAvatar size="xs" username={matchInfo.teamA.name} src={Chelsea} />
      ),
    },
  ];
  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <AppBox flexDirection="column" gap="md">
        <MatchStatisticsGroup />
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">{"Form (Last 5 Matches)"}</AppTitle>
          <AppBox flexDirection="column" gap="sm">
            <AppBox gap="sm">
              <AppTabs
                as="a"
                onClick={(e) => setActiveItem(e)}
                activeValue={currentTab}
                tabItems={tabItems}
                variant="filled"
                shadow="xs"
              />
            </AppBox>
            <Outlet />
          </AppBox>
        </AppBox>
        <AppBox flexDirection="column" gap="xs">
          {/* The design of this card can change in future */}
          <AppTitle as="h5">{"Head to Head Record)"}</AppTitle>
          <AppBox flexDirection="column" gap="sm">
            {HtHResult.map((result) => (
              <MatchResultCard key={result.id} data={result} />
            ))}
          </AppBox>
        </AppBox>
      </AppBox>
      <AppBox flexDirection="column" gap="sm">
        <NewsTimelineCard news={newsData} timelineType="small" />
      </AppBox>
    </AppGridBox>
  );
}
