import { AppTitle } from "../../../commons/components";

export default function StandingTableHeader() {
  const headerItems = [
    {
      dataKey: "team",
      label: "Team",
    },
    {
      dataKey: "gp",
      label: "GP",
    },
    {
      dataKey: "w",
      label: "W",
    },
    {
      dataKey: "d",
      label: "D",
    },
    {
      dataKey: "l",
      label: "L",
    },
    {
      dataKey: "f:a",
      label: "F:A",
    },
    {
      dataKey: "gd",
      label: "GD",
    },
    {
      dataKey: "p",
      label: "P",
    },
  ];
  return (
    <tr>
      {headerItems.map((item, index) => (
        <th key={item.dataKey}>
          <AppTitle textAlign={index === 0 ? "left" : "center"} size="sm">
            {item.label}
          </AppTitle>
        </th>
      ))}
    </tr>
  );
}
