import { AppBox, AppText, AppTitle } from "../../../commons/components";
import AppProgressChart from "../../../commons/components/ui-components/ProgressChart";

interface StatisticsProps {
  label: string;
  value: number | string;
  fillValue: number;
}

export default function StatisticsBar({
  label,
  value,
  fillValue,
}: StatisticsProps) {
  return (
    <AppBox flexDirection="column" gap="2xs">
      <AppBox justifyContent="space-between">
        <AppText fontWeight="medium">{label}</AppText>
        <AppTitle size="sm">{value}</AppTitle>
      </AppBox>
      <AppProgressChart strokeWidth={0.25} value={fillValue} />
    </AppBox>
  );
}
