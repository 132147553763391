import { AppAvatar, AppBox, AppPaper, AppText, AppTitle } from "../../../commons/components";
import AppLineUpBadge from "../../../commons/components/ui-components/LineupBadge";

/**
 * MatchEventStatus : defines the event iin the game like goal, cardplay, subtitute
 * PlayerPositions : defines the position of the player in the game.
 * PlayerRoles : defines the role of the player in the game.
 */
type MatchEventStatus = "SI" | "SO" | "YC" | "DYC" | "RC" | "G" | "OG";
export type PlayerPositions =
  | "GK"
  | "SW"
  | "CB"
  | "LCB"
  | "RCB"
  | "RB"
  | "LB"
  | "CDM"
  | "LDM"
  | "RDM"
  | "LWB"
  | "RWB"
  | "CM"
  | "LCM"
  | "RCM"
  | "LM"
  | "RM"
  | "CAM"
  | "LAM"
  | "RAM"
  | "RW"
  | "LW"
  | "CS"
  | "LS"
  | "RS";

type PlayerRoles = "goalkeeper" | "defender" | "midfielder" | "forward" | "subtitute";

export interface IMatchEvent {
  matchTime: string[];
  matchEvent: MatchEventStatus;
}

export interface IPlayer {
  id: number;
  name: string;
  avatar: string;
  team: string;
  position: PlayerPositions;
  role: PlayerRoles;
  number: number;
  event?: IMatchEvent[];
}

interface MatchLineupProps {
  player: IPlayer;
}

function formatMatchTime(matchTimeArray: string[]) {
  return matchTimeArray.map((time: string) => `${time}'`).join(", ");
}

export default function MatchLineupCard({ player }: MatchLineupProps) {
  const formattedNumber = player.number.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
  return (
    <AppPaper radius="md" padding="xs" shadow="xs">
      <AppBox alignItems="center" gap="xs">
        <AppText as="label" size="sm" color="muted" className="min-w-2">
          {formattedNumber}
        </AppText>
        <AppAvatar username={player.name} src={player.avatar} />
        <AppBox alignItems="center" className="flex-1">
          <AppTitle as={"h5"}>{player.name}</AppTitle>
        </AppBox>
        {player.event && (
          <AppBox alignItems="center" gap="xs">
            {player.event?.map((event, index) => (
              <AppBox gap="2xs" key={index}>
                <AppBox alignItems="center">
                  <AppText fontWeight="semibold" size="lg">
                    {formatMatchTime(event.matchTime)}
                  </AppText>
                </AppBox>
                <AppLineUpBadge status={event.matchEvent} />
              </AppBox>
            ))}
          </AppBox>
        )}
      </AppBox>
    </AppPaper>
  );
}
