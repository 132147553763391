import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppTitle,
} from "../../commons/components";
import IconInfo from "../../commons/components/icons/info";
import NewsTimelineCard, {
  INewsItem,
} from "../../commons/components/ui-components/NewsTimelineCard";
import TeamStandingTable from "../team-detail/components/TeamStandingTable";

export default function MatchStandings() {
  const newsData: INewsItem[] = [
    {
      id: 0,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
      ],
    },
    {
      id: 1,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
      ],
    },
  ];
  const leagueSelectOptions = [
    "Premiere Leagur",
    "Emirates FA Cup",
    "Champions League",
  ];
  const seasonSelectOptions = ["2023/24", "2022/23", "2021/22"];

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <AppBox flexDirection="column" gap="sm">
        <AppBox flexDirection="column" gap="xs">
          <AppBox justifyContent="space-between">
            <AppTitle as="h5">{"Standings"}</AppTitle>
            <IconInfo />
          </AppBox>
          <AppBox gap="sm">
            <AppSelect options={leagueSelectOptions} className="w-1/2" />
            <AppSelect options={seasonSelectOptions} className="w-1/2" />
          </AppBox>
        </AppBox>
        <TeamStandingTable
          activeValue={[5, 8]}
          activeColors={["primary", "accent"]}
        />
      </AppBox>
      <NewsTimelineCard news={newsData} timelineType="small" />
    </AppGridBox>
  );
}
