import { AppBox, AppText, AppTitle } from "..";
interface DateProps {
  date: Date;
}

const monthsAbbreviation = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function AppTimeline({ date }: DateProps) {
  return (
    <AppBox className="timeline-node-container">
      <AppBox
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className="timeline-node">
        <AppTitle as={"h5"} color="primary" textAlign="center">
          {date.getDate()}
        </AppTitle>
        <AppText as="span" size="sm" color="muted" textAlign="center">
          {monthsAbbreviation[date.getMonth()]}
        </AppText>
      </AppBox>
    </AppBox>
  );
}
