import { AppBox, AppGridBox, AppTitle } from "../../commons/components";
import Arsenal from "../../assets/images/clubs/Arsenal.png";
import NewCastleLogo from "../../assets/images/clubs/Newcastel.png";

import Odegard from "../../assets/images/players/Odegaard.png";
import Jesus from "../../assets/images/players/Jesus.png";
import Nelson from "../../assets/images/players/Nelson.png";
import UpcomingMatchCard from "../team-detail/components/UpcomingMatchCard";
import NewsTimelineCard, { INewsItem } from "../../commons/components/ui-components/NewsTimelineCard";
import TopStatsGroup from "../matches/components/TopStatsGroup";

export default function TournamentSummary() {
  const upcomingMatch = [
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
  ];
  const newsData: INewsItem[] = [
    {
      id: 0,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
      ],
    },
    {
      id: 1,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
      ],
    },
  ];
  const playerStats = [
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 24,
      position: "Forward",
      team: "Arsenal",
      statValue: 500,
    },
    {
      id: 1,
      name: "Martin Odegaard",
      avatar: Odegard,
      number: 8,
      position: "Midfielder",
      team: "Arsenal",
      statValue: 300,
    },
    {
      id: 2,
      name: "Gabriel Jesus",
      avatar: Jesus,
      number: 9,
      position: "Forward",
      team: "Arsenal",
      statValue: 48,
    },
    {
      id: 3,
      name: "Gabriel Jesus",
      avatar: Jesus,
      number: 9,
      position: "Forward",
      team: "Arsenal",
      statValue: 48,
    },
    {
      id: 4,
      name: "Gabriel Jesus",
      avatar: Jesus,
      number: 9,
      position: "Forward",
      team: "Arsenal",
      statValue: 48,
    },
  ];

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <AppBox displayBlock={true}>
        <AppBox flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">{"Upcoming Match"}</AppTitle>
            <AppBox flexDirection="column" gap="sm">
              {upcomingMatch.map((match, index) => (
                <UpcomingMatchCard key={index} data={match} />
              ))}
            </AppBox>
          </AppBox>
          <TopStatsGroup statsType="Goal" stats={playerStats} />
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">{"Standings"}</AppTitle>
            todo
          </AppBox>
        </AppBox>
      </AppBox>
      <NewsTimelineCard news={newsData} timelineType="small" />
    </AppGridBox>
  );
}
