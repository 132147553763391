import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setUser, setToken } from "./session-slice";
import { loginService } from "../../commons";
import { User } from "../../commons/models/user";


export const login = createAsyncThunk(
    'auth/login',
    async (credentials: {username: string, password: string}, thunkAPI) => {
        try {
            const loginResponse =  await  loginService.login(credentials);
            thunkAPI.dispatch(setUser(loginResponse.user as User));
            thunkAPI.dispatch(setToken(loginResponse.tokenInfo.accessToken));
            return loginResponse.user;
        }catch(e) {
            return thunkAPI.rejectWithValue(e);
        }
    },
);

const authSlice = createSlice({
    name: 'auth',
    initialState: null,
    reducers: {},
});

export default authSlice.reducer;