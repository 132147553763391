import {
  AppBox,
  AppGridBox,
  AppText,
  AppTitle,
  InfoCard,
} from "../../../commons/components";
import PositionAndInfoCard from "./PositionAndInfoCard";

export default function PlayerInfo() {
  const basicInfo = [
    {
      title: "Arsenal",
      label: "Club",
    },
    {
      title: "24 Years",
      label: "Age",
    },
    {
      title: "178 cm",
      label: "Height",
    },
    {
      title: "Left",
      label: "Preferred Foot",
    },
    {
      title: "Midfielder",
      label: "Position",
    },
    {
      title: "Squad Number",
      label: "08",
    },
  ];

  const playerInfo = {
    positions: ["CM", "CAM"],
    strenght: "Passing, Playmaking, Tackling, Shooting, High Pressing",
    weakeness: "No Particular Weakenesses",
    description:
      "Arsenal floated between a handful of different pitches in the Plumstead area in their early years. They started life on Plumstead Common but, for their second season, Royal Arsenal rented the nearby Sportsman Ground on Plumstead Marshes. It was here that the Club would enter the London Senior Cup for the first time. Although they were beaten by Barnes in Round Two the wheels were in motion. With increasing support, the Club rented the Manor Ground the following season.",
  };

  return (
    <AppBox flexDirection="column" gap="md">
      <AppBox flexDirection="column" gap="xs">
        <AppTitle as="h5">Basic Information</AppTitle>
        <AppBox flexDirection="column" gap="sm">
          <AppGridBox columns={3} gap="sm">
            {basicInfo.map((item) => (
              <InfoCard
                key={item.label}
                alignItems="start"
                shadow="xs"
                title={item.title}
                label={item.label}
              />
            ))}
          </AppGridBox>
          <PositionAndInfoCard
            positions={playerInfo.positions}
            strength={playerInfo.strenght}
            weakeness={playerInfo.weakeness}
          />
          <AppText size="lg" fontWeight="medium">
            {playerInfo.description}
          </AppText>
        </AppBox>
      </AppBox>
    </AppBox>
  );
}
