import { AppGridBox } from "../../commons/components";
import NewsTimelineCard, {
  INewsItem,
} from "../../commons/components/ui-components/NewsTimelineCard";

export default function TournamentNews() {
  const newsData: INewsItem[] = [
    {
      id: 0,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
      ],
    },
    {
      id: 1,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
      ],
    },
  ];

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <NewsTimelineCard news={newsData} timelineType="big"/>
      <NewsTimelineCard news={newsData} timelineType="small" title="Latest News"/>
    </AppGridBox>
  );
}
