import { useEffect, useState } from "react";
import { AppBox, AppGridBox, AppTitle } from "../../commons/components";
import AppTabs, { TabItem } from "../../commons/components/Tabs";
import { Outlet, useLocation } from "react-router-dom";
import NewsTimelineCard, { INewsItem } from "../../commons/components/ui-components/NewsTimelineCard";

export default function MatchCommentary() {
  const [currentTab, setCurrentTab] = useState("all-commentary");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const location = useLocation();

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  const tabItems = [
    {
      label: "All Commentary",
      value: "all-commentary",
      href: "all-commentary",
    },
    {
      label: "Key Events",
      value: "key-events",
      href: "key-events",
    },
  ];

  const newsData: INewsItem[] = [
    {
      id: 0,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
      ],
    },
    {
      id: 1,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
      ],
    },
  ];

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <AppBox displayBlock={true}>
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">{"Match Commentary"}</AppTitle>
          <AppBox>
            <AppTabs as="a" onClick={(e) => setActiveItem(e)} activeValue={currentTab} tabItems={tabItems} variant="filled" shadow="xs" />
          </AppBox>
          <Outlet />
        </AppBox>
      </AppBox>
      <NewsTimelineCard news={newsData} timelineType="small"/>
    </AppGridBox>
  );
}
