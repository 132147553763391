import { AppBox, AppGridBox, AppSelect, AppTitle } from "../../commons/components";
import Arsenal from "../../assets/images/clubs/Arsenal.png";
import Aaron from "../../assets/images/players/Ramsdale.png";
import Nelson from "../../assets/images/players/Nelson.png";
import TournamentBasicInfoCard from "./conponents/TournamentBasicInfoCard";
import NewsTimelineCard, { INewsItem } from "../../commons/components/ui-components/NewsTimelineCard";
import BestPlayerCard from "../team-detail/components/BestPlayerCard";
import { ITournamentBasicInfo } from "../../commons/components/interface/i-tournament";

const newsData: INewsItem[] = [
  {
    id: 0,
    date: new Date(),
    news: [
      {
        title: "This is a news card with vertical content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
      },
      {
        title: "This is a news card with vertical content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
      },
      {
        title: "This is a news card with vertical content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
      },
    ],
  },
  {
    id: 1,
    date: new Date(),
    news: [
      {
        title: "This is a news card with vertical content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
      },
      {
        title: "This is a news card with horizontal content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
      },
      {
        title: "This is a news card with horizontal content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
      },
    ],
  },
];

const playerOfTheGame = [
  {
    id: 1,
    name: "Aaron Ramsdale",
    role: "Goalkeeper",
    avatar: Aaron,
    clubLogo: Arsenal,
    date: "30 Jan, 2023",
    opponent: "Bournemouth",
    awardTitle: "Save of the Season",
  },
  {
    id: 2,
    name: "Reiss Nelson",
    role: "Forward",
    avatar: Nelson,
    clubLogo: Arsenal,
    date: "30 Jan, 2023",
    opponent: "Bournemouth",
    awardTitle: "Player of the Season",
  },
  {
    id: 3,
    name: "Reiss Nelson",
    role: "Forward",
    avatar: Nelson,
    clubLogo: Arsenal,
    date: "30 Jan, 2023",
    opponent: "Bournemouth",
    awardTitle: "Goal of the Season",
  },
  {
    id: 4,
    name: "Reiss Nelson",
    role: "Forward",
    avatar: Nelson,
    clubLogo: Arsenal,
    date: "30 Jan, 2023",
    opponent: "Bournemouth",
    awardTitle: "Golden Boot",
  },
  {
    id: 5,
    name: "Reiss Nelson",
    role: "Forward",
    avatar: Nelson,
    clubLogo: Arsenal,
    date: "30 Jan, 2023",
    opponent: "Bournemouth",
    awardTitle: "Golden Glove",
  },
  {
    id: 6,
    name: "Reiss Nelson",
    role: "Forward",
    avatar: Nelson,
    clubLogo: Arsenal,
    date: "30 Jan, 2023",
    opponent: "Bournemouth",
    awardTitle: "Game Changer",
  },
];

const selectOptions = ["2023/24", "2022/23", "2021/22"];

export default function TournamentProfile() {
  const basicInfo: ITournamentBasicInfo = {
    tournamentType: "League",
    duration: "11 Aug - 19 May",
    teamsGender: "Men's",
    teamsAge: "20+ Years",
    teamFormation: "11 vs 11",
    teamSkillLevel: "Competitive",
    description:
      "Clubs have the opportunity to propose new rules or amendments at the Shareholder meeting. Each Member Club is entitled to one vote and all rule changes and major commercial contracts require the support of at least a two-thirds vote, or 14 clubs, to be agreed.",
  };

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <AppBox displayBlock={true}>
        <AppBox flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">{"Basic Information"}</AppTitle>
            <TournamentBasicInfoCard tournamentData={basicInfo} />
          </AppBox>
          <NewsTimelineCard news={newsData} timelineType="big" />
        </AppBox>
      </AppBox>
      <AppBox flexDirection="column" gap="sm">
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">{"Awards & Trophies"}</AppTitle>
          <AppSelect options={selectOptions} />
        </AppBox>
        <AppBox flexDirection="column" gap="sm">
          {playerOfTheGame.map((item) => (
            <BestPlayerCard key={item.id} playerInfo={item} />
          ))}
        </AppBox>
      </AppBox>
    </AppGridBox>
  );
}
