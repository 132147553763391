import { AppBox, AppGridBox, AppSelect, AppTitle } from "../../commons/components";
import NewsTimelineCard, { INewsItem } from "../../commons/components/ui-components/NewsTimelineCard";
import AppTabs, { TabItem } from "../../commons/components/Tabs";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

export default function TournamentStatistics() {
  const [currentTab, setCurrentTab] = useState("players-stats");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const location = useLocation();

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  const tabItems = [
    {
      label: "Players",
      value: "players-stats",
      href: "players-stats",
    },
    {
      label: "Teams",
      value: "teams-stats",
      href: "teams-stats",
    },
  ];

  const newsData: INewsItem[] = [
    {
      id: 0,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
      ],
    },
    {
      id: 1,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
      ],
    },
  ];
  const selectOptions = ["Goal", "Assist", "Saves"];

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <AppBox flexDirection="column" gap="sm">
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">{"League Statistics"}</AppTitle>
          <AppSelect options={selectOptions} className="w-1/2" />
        </AppBox>
        <AppTabs
          tabItemClassName="flex-1"
          className="w-1/2"
          as="a"
          onClick={(e) => setActiveItem(e)}
          activeValue={currentTab}
          tabItems={tabItems}
          variant="filled"
          shadow="xs"
        />
        <Outlet />
      </AppBox>
      <NewsTimelineCard news={newsData} timelineType="small" />
    </AppGridBox>
  );
}
