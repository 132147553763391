import {
  AppAvatar,
  AppBox,
  AppIconButton,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../commons/components";
import AppBorderBox from "../../../commons/components/BorderBox";
import IconAlert from "../../../commons/components/icons/alert";
import IllustrationBall from "../../../commons/components/illustrations/IllustrationBall";

interface UpcomingTrainingProps {
  logo: string;
  name: string;
  description: string;
  date: string;
  location: string;
}

export default function UpcomingTrainingCard({
  trainingData,
}: {
  trainingData: UpcomingTrainingProps;
}) {
  return (
    <AppPaper shadow="xs" padding="xs">
      <AppBox justifyContent="space-between" alignItems="center">
        <AppBox alignItems="center" gap="xs">
          <AppAvatar
            username={trainingData.name}
            variant="outline"
            icon={<IllustrationBall />}
          />
          <AppTitle truncate as="h6">
            {trainingData.description}
          </AppTitle>
        </AppBox>
        <AppBorderBox border={["Left"]} pl="xs">
          <AppBox gap="xs" alignItems="center">
            <AppBox flexDirection="column" gap="3xs">
              <AppText as="label" size="sm" color="muted">
                {trainingData.date}
              </AppText>
              <AppText as="label" size="sm" color="muted">
                {trainingData.location}
              </AppText>
            </AppBox>
            <AppIconButton size="lg" icon={<IconAlert />} variant="light" />
          </AppBox>
        </AppBorderBox>
      </AppBox>
    </AppPaper>
  );
}
