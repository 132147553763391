import { ReactNode } from "react";
import AppBox from "./Box";
import { ShadowSizes } from "../types/core-types";
import { NavLink } from "react-router-dom";
export type TabType = "default" | "filled";

export interface TabItem {
  label: string;
  value: string;
  href?: string;
  icon?: ReactNode;
}

interface TabProps {
  as?: "button" | "a";
  variant?: TabType;
  tabItems: Array<TabItem>;
  activeValue?: string;
  shadow?: ShadowSizes;
  className?: string;
  tabItemClassName?: string;
  onClick?: (item: TabItem) => void;
}

interface TabElement {
  as?: "button" | "a";
  item: TabItem;
  activeValue?: string;
  ClassName?: string;
  onClick?: (item: TabItem) => void;
}

export default function AppTabs({
  as = "a",
  tabItems,
  activeValue,
  variant = "default",
  shadow,
  className,
  tabItemClassName,
  onClick,
}: TabProps) {
  const classNames = [
    `tab`,
    variant && `tab-${variant}`,
    shadow && `shadow-${shadow}`,
    className,
  ]
    .filter(Boolean)
    .join(" ");
  return (
    <AppBox className={classNames}>
      {tabItems.map((item, index) => (
        <AppTabItem
          as={as}
          activeValue={activeValue}
          item={item}
          key={index}
          ClassName={tabItemClassName}
          onClick={onClick}
        />
      ))}
    </AppBox>
  );
}

export function AppTabItem({
  item,
  as,
  activeValue,
  ClassName,
  onClick,
}: TabElement) {
  const classNames = [
    `tab-item`,
    ClassName,
    activeValue === item.value && `active`,
  ]
    .filter(Boolean)
    .join(" ");

  const handleClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  if (as === "button") {
    return (
      <button onClick={handleClick} className={classNames} key={item.value}>
        {item.icon && item.icon}
        <span>{item.label}</span>
      </button>
    );
  } else {
    if (!item.href) {
      throw new Error('href attribute is required for "anchor" tab item');
    }

    return (
      <NavLink
        to={item.href}
        onClick={handleClick}
        className={classNames}
        key={item.value}
      >
        {item.icon && item.icon}
        <span>{item.label}</span>
      </NavLink>
    );
  }
}
