import {
  AppAvatar,
  AppBox,
  AppButton,
  AppGridBox,
  AppIconButton,
  AppPaper,
  AppScorePill,
  AppTitle,
} from "../../../commons/components";
import IconAlert from "../../../commons/components/icons/alert";

interface MatchProps {
  date?: string;
  league?: string;
  status?: string;
  teamA: { logo: string; name: string; goal?: string };
  teamB: {
    logo: string;
    name: string;
    goal?: string;
  };
}

export default function UpcomingMatchCard({ data }: { data: MatchProps }) {
  return (
    <AppPaper className="match-card" shadow="xs" padding="xs">
      <AppGridBox className="match-card-grid" gap="sm">
        <AppGridBox className="left-section align-center" gap="xs">
          <AppBox gap="xs" justifyContent="end" alignItems="center">
            <AppTitle truncate={true} as="h6">
              {data.teamA.name}
            </AppTitle>
            <AppAvatar username={data.teamA.name} src={data.teamA.logo} />
          </AppBox>
          <AppBox justifyContent='center'>
            <AppScorePill matchStatus="upcoming" />
          </AppBox>
          <AppBox alignItems="center" gap="xs" justifyContent="start">
            <AppAvatar username={data.teamB.name} src={data.teamB.logo} />
            <AppTitle truncate={true} as="h6">
              {data.teamB.name}
            </AppTitle>
          </AppBox>
        </AppGridBox>
        <AppBox gap="xs" justifyContent="end">
          <AppIconButton
            icon={<IconAlert />}
            size="lg"
            variant="light"
          ></AppIconButton>
          <AppButton
            size="lg"
            variant="light"
            label="Match Details"
          ></AppButton>
        </AppBox>
      </AppGridBox>
    </AppPaper>
  );
}
