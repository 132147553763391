import {
  AppAvatar,
  AppBox,
  AppButton,
  AppGridBox,
  AppIconButton,
  AppPaper,
  AppScorePill,
  AppTitle,
} from "../../../commons/components";
import Pill from "../../../commons/components/Pill";
import IconAlert from "../../../commons/components/icons/alert";
import IconLive from "../../../commons/components/icons/live";

interface MatchProps {
  time: string;
  teamA: {
    logo: string;
    name: string;
    goal: number;
  };
  teamB: {
    logo: string;
    name: string;
    goal: number;
  };
}

export default function LiveMatchCard({ data }: { data: MatchProps }) {
  return (
    <AppPaper className="match-card" shadow="xs" padding="xs">
      <AppGridBox className="match-card-grid" gap="sm">
        <AppGridBox className="align-center live-left-section" gap="xs">
          <AppBox justifyContent="end" alignItems="center" gap="xs">
            <AppTitle truncate={true} as="h6">
              {data.teamA.name}
            </AppTitle>
            <AppAvatar username={data.teamA.name} src={data.teamA.logo} />
          </AppBox>
          <AppBox flexDirection="column" alignItems="center" gap="2xs">
            <AppScorePill
              matchStatus="live"
              valueFirst={data.teamA.goal}
              valueSecond={data.teamB.goal}
            />
            <Pill icon={<IconLive />} label={"live | 90+4"}></Pill>
          </AppBox>
          <AppBox justifyContent="start" alignItems="center" gap="xs">
            <AppAvatar username={data.teamB.name} src={data.teamB.logo} />
            <AppTitle truncate={true} as="h6">
              {data.teamB.name}
            </AppTitle>
          </AppBox>
        </AppGridBox>
        <AppBox gap="xs" justifyContent="end">
          <AppIconButton
            icon={<IconAlert />}
            size="lg"
            variant="light"
          ></AppIconButton>
          <AppButton
            size="lg"
            variant="light"
            label="Match Details"
          ></AppButton>
        </AppBox>
      </AppGridBox>
    </AppPaper>
  );
}
