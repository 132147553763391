import {
  AppAvatar,
  AppBox,
  AppPaper,
  AppText,
  AppTitle,
  InfoCard,
} from "../../../commons/components";
import AppGridBox from "../../../commons/components/GridBox";
import { SurfaceColor } from "../../../commons/types/core-types";
export interface PlayerInfoProps {
  name: string;
  avatar: string;
  position: string;
  number: number;
  appearance: number;
  goal: number;
  assist: number;
  shot: number;
}

export default function FeaturedPlayerCard({
  playerInfo,
}: {
  playerInfo: PlayerInfoProps;
}) {
  return (
    <AppPaper shadow="xs" padding="xs">
      <AppBox flexDirection="column" gap="xs">
        <AppBox gap="xs" alignItems="center">
          <AppAvatar
            username={playerInfo.name}
            src={playerInfo.avatar}
            size="xl"
            withBorder={true}
          />
          <AppBox flexDirection="column">
            <AppTitle as="h5">{playerInfo.name}</AppTitle>
            <AppText as="label" size="sm" color="muted">
              {playerInfo.number} | {playerInfo.position}
            </AppText>
          </AppBox>
        </AppBox>
        <AppGridBox columns={2} gap="xs">
          <InfoCard
            titleColor="primary"
            paperBgColor={SurfaceColor.surface3}
            label={"Appearances"}
            title={playerInfo.appearance}
          ></InfoCard>
          <InfoCard
            titleColor="primary"
            paperBgColor={SurfaceColor.surface3}
            label={"Goals"}
            title={playerInfo.goal}
          ></InfoCard>
          <InfoCard
            titleColor="primary"
            paperBgColor={SurfaceColor.surface3}
            label={"Assists"}
            title={playerInfo.assist}
          ></InfoCard>
          <InfoCard
            titleColor="primary"
            paperBgColor={SurfaceColor.surface3}
            label={"Shots"}
            title={playerInfo.shot}
          ></InfoCard>
        </AppGridBox>
      </AppBox>
    </AppPaper>
  );
}
