import { AppBox, AppGridBox, AppTitle } from "../../commons/components";
import NewsTimelineCard, { INewsItem } from "../../commons/components/ui-components/NewsTimelineCard";
import ManCity from "../../assets/images/clubs/ManCity.png";
import ManUnited from "../../assets/images/clubs/ManUnited.png";
import Arsenal from "../../assets/images/clubs/Arsenal.png";
import AstonVilla from "../../assets/images/clubs/AstonVilla.png";
import Chelsea from "../../assets/images/clubs/Chelsea.png";
import CrystalPalace from "../../assets/images/clubs/CrystalPalace.png";
import Everton from "../../assets/images/clubs/Everton.png";
import Leicester from "../../assets/images/clubs/Leicester.png";
import Newcastel from "../../assets/images/clubs/Newcastel.png";
import WestHam from "../../assets/images/clubs/WestHam.png";
import TournamentTeamCard from "./conponents/TournamentTeamCard";

export default function TournamentTeams() {
  const newsData: INewsItem[] = [
    {
      id: 0,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
      ],
    },
    {
      id: 1,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: <img alt="" src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h" />,
        },
      ],
    },
  ];
  const teamData = [
    {
      id: 0,
      title: "Manchester City",
      avatar: ManCity,
      foundDate: "1 Dec, 1886",
    },
    {
      id: 1,
      title: "Manchester United",
      avatar: ManUnited,
      foundDate: "1 Dec, 1886",
    },
    {
      id: 2,
      title: "West Ham United",
      avatar: WestHam,
      foundDate: "1 Dec, 1886",
    },
    {
      id: 3,
      title: "Arsenal",
      avatar: Arsenal,
      foundDate: "1 Dec, 1886",
    },
    {
      id: 4,
      title: "Aston Villa",
      avatar: AstonVilla,
      foundDate: "1 Dec, 1886",
    },
    {
      id: 5,
      title: "Chelsea",
      avatar: Chelsea,
      foundDate: "1 Dec, 1886",
    },
    {
      id: 6,
      title: "Crystal Palace",
      avatar: CrystalPalace,
      foundDate: "1 Dec, 1886",
    },
    {
      id: 7,
      title: "Everton",
      avatar: Everton,
      foundDate: "1 Dec, 1886",
    },
    {
      id: 8,
      title: "Leicester City",
      avatar: Leicester,
      foundDate: "1 Dec, 1886",
    },
    {
      id: 9,
      title: "Newcastle United",
      avatar: Newcastel,
      foundDate: "1 Dec, 1886",
    },
  ];

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <AppBox displayBlock={true}>
        <AppBox flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">{"Associated Teams"}</AppTitle>
            <AppGridBox columns={2} gap="sm">
              {teamData.map((item) => (
                <TournamentTeamCard key={item.id} avatar={item.avatar} title={item.title} label={item.foundDate} />
              ))}
            </AppGridBox>
          </AppBox>
        </AppBox>
      </AppBox>
      <NewsTimelineCard news={newsData} timelineType="small"/>
    </AppGridBox>
  );
}
