import { AppButton, AppContainer, AppTitle } from "../commons/components";

function Home() {
  return (
    <>
      <AppContainer>
        <AppTitle>I am from Home.</AppTitle>
        <AppButton label="learn react" />
      </AppContainer>
    </>
  );
}
export default Home;
