import { CSSProperties } from "react";
import { AppBox, AppPaper, AppText, AppTitle } from "..";
import { ShadowSizes, SurfaceColors, TextColors } from "../../types/core-types";
interface InfoProps {
  title: string | number;
  label: string;
  titleColor?: TextColors;
  paperBgColor?: SurfaceColors;
  alignItems?: CSSProperties["alignItems"];
  shadow?: ShadowSizes;
}
export default function InfoCard({
  title,
  label,
  titleColor,
  paperBgColor = 100,
  alignItems = "center",
  shadow = "none",
}: InfoProps) {
  const alignmentMapping: { [key: string]: CSSProperties["textAlign"] } = {
    center: "center",
    "flex-start": "left",
    start: "left",
    "flex-end": "right",
    end: "right",
  };

  const textAlign = alignmentMapping[alignItems];

  return (
    <AppPaper shadow={shadow} radius="sm" bgColor={paperBgColor} padding="xs">
      <AppBox flexDirection="column" alignItems={alignItems}>
        <AppTitle textAlign={textAlign} as={"h5"} color={titleColor}>
          {title}
        </AppTitle>
        <AppText textAlign={textAlign} as="label" size="sm" color="muted">
          {label}
        </AppText>
      </AppBox>
    </AppPaper>
  );
}
