import { AppBox, AppContainer } from "../../commons/components";
import { Outlet } from "react-router";
import Odengaard from "../../assets/images/players/Odegaard.png";
import DetailHeader from "../team-detail/components/DetailHeader";

export default function PlayerDetail() {
  const teamInfo = {
    logo: Odengaard,
    name: "Martin Odengaard",
    label: "Arsenal debut: 30 Jan, 2023 | Premier League",
  };

  const tabItems = [
    {
      label: "Summary",
      value: "summary",
      href: "summary",
    },
    {
      label: "Profile",
      value: "profile",
      href: "profile",
    },

    {
      label: "Events",
      value: "events",
      href: "events",
    },
    {
      label: "Statistics",
      value: "statistics",
      href: "statistics",
    },
    {
      label: "News",
      value: "news",
      href: "news",
    },
  ];

  return (
    <AppContainer>
      <AppBox py="md" flexDirection="column" gap="md">
        <DetailHeader tabItems={tabItems} headerInfo={teamInfo}></DetailHeader>
        <Outlet />
      </AppBox>
    </AppContainer>
  );
}
