import {
  AppAvatar,
  AppBox,
  AppDivider,
  AppDoughnutChart,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../commons/components";
import Arsenal from "../../../assets/images/clubs/Arsenal.png";
import Chelsea from "../../../assets/images/clubs/Chelsea.png";
import AppProgressChart from "../../../commons/components/ui-components/ProgressChart";

type StatPositionProps = "left" | "right";

const matchInfo = {
  data: {
    date: new Date() as unknown as string,
    league: "English Premiere League",
    status: "completed",
    teamA: {
      logo: Arsenal,
      name: "Arsenal",
      goal: 3,
      possession: 55,
      shots: 16,
      shotsOnTarget: 10,
      chancesCreated: 0,
      passes: 543,
      crosses: 7,
      saves: 3,
      clearances: 12,
      yellowCard: 0,
      redCard: 0,
      offsides: 1,
      fouls: 3,
    },
    teamB: {
      logo: Chelsea,
      name: "Chelsea",
      goal: 1,
      possession: 45,
      shots: 7,
      shotsOnTarget: 4,
      chancesCreated: 0,
      passes: 445,
      crosses: 2,
      saves: 6,
      clearances: 22,
      yellowCard: 2,
      redCard: 0,
      offsides: 0,
      fouls: 6,
    },
  },
};
export default function MatchStatisticsGroup() {
  const value = (
    statPosition: StatPositionProps,
    valueA: number,
    valueB: number
  ): number => {
    if (valueA === 0 && valueB === 0) {
      return 0;
    } else {
      if (statPosition === "left") {
        return Math.floor((valueA / (valueA + valueB)) * 100);
      } else {
        return Math.floor((valueB / (valueA + valueB)) * 100);
      }
    }
  };

  const matchStatsItems = [
    {
      title: "Shots",
      valueLeft: matchInfo.data.teamA.shots,
      valueRight: matchInfo.data.teamB.shots,
      chartValueLeft: value(
        "left",
        matchInfo.data.teamA.shots,
        matchInfo.data.teamB.shots
      ),
      chartValueRight: value(
        "right",
        matchInfo.data.teamA.shots,
        matchInfo.data.teamB.shots
      ),
    },
    {
      title: "Shots on Target",
      valueLeft: matchInfo.data.teamA.shotsOnTarget,
      valueRight: matchInfo.data.teamB.shotsOnTarget,
      chartValueLeft: value(
        "left",
        matchInfo.data.teamA.shotsOnTarget,
        matchInfo.data.teamB.shotsOnTarget
      ),
      chartValueRight: value(
        "right",
        matchInfo.data.teamA.shotsOnTarget,
        matchInfo.data.teamB.shotsOnTarget
      ),
    },
    {
      title: "Chances Created",
      valueLeft: matchInfo.data.teamA.chancesCreated,
      valueRight: matchInfo.data.teamB.chancesCreated,
      chartValueLeft: value(
        "left",
        matchInfo.data.teamA.chancesCreated,
        matchInfo.data.teamB.chancesCreated
      ),
      chartValueRight: value(
        "right",
        matchInfo.data.teamA.chancesCreated,
        matchInfo.data.teamB.chancesCreated
      ),
    },
    {
      title: "Passes",
      valueLeft: matchInfo.data.teamA.passes,
      valueRight: matchInfo.data.teamB.passes,
      chartValueLeft: value(
        "left",
        matchInfo.data.teamA.passes,
        matchInfo.data.teamB.passes
      ),
      chartValueRight: value(
        "right",
        matchInfo.data.teamA.passes,
        matchInfo.data.teamB.passes
      ),
    },
    {
      title: "Crosses",
      valueLeft: matchInfo.data.teamA.crosses,
      valueRight: matchInfo.data.teamB.crosses,
      chartValueLeft: value(
        "left",
        matchInfo.data.teamA.crosses,
        matchInfo.data.teamB.crosses
      ),
      chartValueRight: value(
        "right",
        matchInfo.data.teamA.crosses,
        matchInfo.data.teamB.crosses
      ),
    },
    {
      title: "Saves",
      valueLeft: matchInfo.data.teamA.saves,
      valueRight: matchInfo.data.teamB.saves,
      chartValueLeft: value(
        "left",
        matchInfo.data.teamA.saves,
        matchInfo.data.teamB.saves
      ),
      chartValueRight: value(
        "right",
        matchInfo.data.teamA.saves,
        matchInfo.data.teamB.saves
      ),
    },
    {
      title: "Clearances",
      valueLeft: matchInfo.data.teamA.clearances,
      valueRight: matchInfo.data.teamB.clearances,
      chartValueLeft: value(
        "left",
        matchInfo.data.teamA.clearances,
        matchInfo.data.teamB.clearances
      ),
      chartValueRight: value(
        "right",
        matchInfo.data.teamA.clearances,
        matchInfo.data.teamB.clearances
      ),
    },
    {
      title: "Yellow Cards",
      valueLeft: matchInfo.data.teamA.yellowCard,
      valueRight: matchInfo.data.teamB.yellowCard,
      chartValueLeft: value(
        "left",
        matchInfo.data.teamA.yellowCard,
        matchInfo.data.teamB.yellowCard
      ),
      chartValueRight: value(
        "right",
        matchInfo.data.teamA.yellowCard,
        matchInfo.data.teamB.yellowCard
      ),
    },
    {
      title: "Red Cards",
      valueLeft: matchInfo.data.teamA.redCard,
      valueRight: matchInfo.data.teamB.redCard,
      chartValueLeft: value(
        "left",
        matchInfo.data.teamA.redCard,
        matchInfo.data.teamB.redCard
      ),
      chartValueRight: value(
        "left",
        matchInfo.data.teamA.redCard,
        matchInfo.data.teamB.redCard
      ),
    },
    {
      title: "Offsides",
      valueLeft: matchInfo.data.teamA.offsides,
      valueRight: matchInfo.data.teamB.offsides,
      chartValueLeft: value(
        "left",
        matchInfo.data.teamA.offsides,
        matchInfo.data.teamB.offsides
      ),
      chartValueRight: value(
        "right",
        matchInfo.data.teamA.offsides,
        matchInfo.data.teamB.offsides
      ),
    },
    {
      title: "Fouls",
      valueLeft: matchInfo.data.teamA.fouls,
      valueRight: matchInfo.data.teamB.fouls,
      chartValueLeft: value(
        "left",
        matchInfo.data.teamA.fouls,
        matchInfo.data.teamB.fouls
      ),
      chartValueRight: value(
        "left",
        matchInfo.data.teamA.fouls,
        matchInfo.data.teamB.fouls
      ),
    },
  ];

  return (
    <AppBox flexDirection="column" gap="xs">
      <AppTitle as="h5">{"Match Statistics"}</AppTitle>
      <AppPaper radius="md" shadow="xs" padding="sm">
        <AppBox flexDirection="column" gap="sm">
          <AppBox>
            <AppBox
              className="flex-1"
              alignItems="center"
              gap="2xs"
              justifyContent="end"
            >
              <AppText size="lg" fontWeight="extrabold">
                {matchInfo.data.teamA.name}
              </AppText>
              <AppAvatar
                username={matchInfo.data.teamA.name}
                variant="outline"
                src={matchInfo.data.teamA.logo}
              />
            </AppBox>
            <AppDivider orientation="vertical" color="dark" mx="3xl" />
            <AppBox className="flex-1" alignItems="center" gap="2xs">
              <AppAvatar
                username={matchInfo.data.teamB.name}
                variant="outline"
                src={matchInfo.data.teamB.logo}
              />
              <AppText size="lg" fontWeight="extrabold">
                {matchInfo.data.teamB.name}
              </AppText>
            </AppBox>
          </AppBox>
          <AppBox alignItems="center" gap="sm">
            <AppBox className="flex-1" justifyContent="end">
              <AppDoughnutChart
                height="160px"
                width="160px"
                color="primary"
                value={value(
                  "left",
                  matchInfo.data.teamA.possession,
                  matchInfo.data.teamB.possession
                )}
                info={{
                  value: `${value(
                    "left",
                    matchInfo.data.teamA.possession,
                    matchInfo.data.teamB.possession
                  )}%`,
                }}
              />
            </AppBox>
            <AppText textAlign="center" color="muted">
              {"Possession"}
            </AppText>
            <AppBox className="flex-1">
              <AppDoughnutChart
                height="160px"
                width="160px"
                color="accent"
                value={value(
                  "right",
                  matchInfo.data.teamA.possession,
                  matchInfo.data.teamB.possession
                )}
                info={{
                  value: `${value(
                    "right",
                    matchInfo.data.teamA.possession,
                    matchInfo.data.teamB.possession
                  )}%`,
                }}
              />
            </AppBox>
          </AppBox>
          <AppBox flexDirection="column" py="2xs">
            {matchStatsItems.map((item) => (
              <AppBox flexDirection="column" gap="xs" py="xs">
                <AppBox justifyContent="center" gap="xs" alignItems="center">
                  <AppText fontWeight="bold" className="stat-width">
                    {item.valueLeft}
                  </AppText>
                  <AppText color="muted" className="flex-1" textAlign="center">
                    {item.title}
                  </AppText>
                  <AppText
                    fontWeight="bold"
                    className="stat-width"
                    textAlign="right"
                  >
                    {item.valueRight}
                  </AppText>
                </AppBox>
                <AppBox gap="xs">
                  <AppBox className="flex-1" justifyContent="end">
                    <AppProgressChart
                      value={item.chartValueLeft}
                      orientation="right-to-left"
                    />
                  </AppBox>
                  <AppBox className="flex-1">
                    <AppProgressChart
                      value={item.chartValueRight}
                      color="accent"
                    />
                  </AppBox>
                </AppBox>
              </AppBox>
            ))}
          </AppBox>
        </AppBox>
      </AppPaper>
    </AppBox>
  );
}
