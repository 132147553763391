import { AppBox, AppPaper, AppCommentaryBadge, AppText } from "../../../commons/components";

type CommentaryStatus = "C" | "S" | "YC" | "DYC" | "RC" | "G" | "OG";

export interface IMatchCommentary {
  matchId: number;
  date?: string;
  league?: string;
  status: CommentaryStatus;
  label: string;
  matchTime: string;
}

interface MatchCommentaryProps {
  commentary: IMatchCommentary;
}

export default function MatchCommentaryCard({ commentary }: MatchCommentaryProps) {
  return (
    <AppPaper shadow="xs" padding="sm" style={{ position: "relative" }}>
      <AppBox gap="sm">
        {commentary.status && (
          <AppBox
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              left: "-1rem",
            }}
          >
            <AppCommentaryBadge status={commentary.status} />
          </AppBox>
        )}
        <AppBox alignItems="center" gap="xs">
          <AppBox className="match-time-width">
            <AppText as="p" fontWeight="extrabold">
              {commentary.matchTime}
            </AppText>
          </AppBox>
          <AppBox>
            <AppText as="p" color="muted">
              {commentary.label}
            </AppText>
          </AppBox>
        </AppBox>
      </AppBox>
    </AppPaper>
  );
}
