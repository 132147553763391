import { AppBox, AppButton, AppTitle } from "../../../commons/components";
import MatchCommentaryCard, { IMatchCommentary } from "./MatchCommentaryCard";

const matchCommentary: IMatchCommentary[] = [
  {
    matchId: 1,
    status: "G",
    matchTime: "105+1'",
    label:
      "Attempt blocked. Bukayo Saka (Arsenal) left footed shot from the right side of the box is blocked. Assisted by Gabriel Jesus.",
  },
  {
    matchId: 1,
    status: "DYC",
    matchTime: "90+6'",
    label: "Mykhailo Mudryk (Chelsea) wins a free kick in the defensive half.",
  },
  {
    matchId: 1,
    status: "S",
    matchTime: "75'",
    label:
      "Attempt blocked. Bukayo Saka (Arsenal) left footed shot from the right side of the box is blocked. Assisted by Gabriel Jesus.",
  },
  {
    matchId: 1,
    status: "RC",
    matchTime: "45+2'",
    label: "Mykhailo Mudryk (Chelsea) wins a free kick in the defensive half.",
  },
  {
    matchId: 1,
    status: "YC",
    matchTime: "18'",
    label: "Mykhailo Mudryk (Chelsea) wins a free kick in the defensive half.",
  },
  {
    matchId: 1,
    status: "C",
    matchTime: "1'",
    label:
      "Attempt blocked. Bukayo Saka (Arsenal) left footed shot from the right side of the box is blocked. Assisted by Gabriel Jesus.",
  },
];

export default function MatchCommentaryGroup() {
  return (
    <AppBox flexDirection="column" gap="xs">
      <AppTitle as="h5">{"Match Commentary"}</AppTitle>
      <AppBox flexDirection="column" gap="sm" pl="xs">
        {matchCommentary.map((commentaryItem,index) => (
          <MatchCommentaryCard key={index} commentary={commentaryItem} />
        ))}
        <AppBox className="w-1/2 mx-auto" flexDirection="column">
          <AppButton
            as="a"
            fullWidth
            size="lg"
            variant="light"
            label="Full Commentary"
          />
        </AppBox>
      </AppBox>
    </AppBox>
  );
}
