import {
  AppBox,
  AppButton,
  AppText,
  AppTitle,
} from "../../../commons/components";
import TopStatsCard from "./TopStatsCard";

export interface IPlayerStats {
  id: number;
  name: string;
  avatar: string;
  number: number;
  position: string;
  team: string;
  statValue: number;
}
export interface TopStatsProps {
  statsType: string;
  stats: IPlayerStats[];
}

export default function TopStatsGroup({ stats, statsType }: TopStatsProps) {
  return (
    <AppBox flexDirection="column" gap="xs">
      <AppTitle as="h5">{"League Statistics"}</AppTitle>
      <AppBox flexDirection="column" gap="2xs">
        <AppText>{statsType}</AppText>
        <AppBox flexDirection="column" gap="sm">
          {stats.map((player, index) => (
            <TopStatsCard
              key={index}
              player={player}
              rank={player.id + 1}
            ></TopStatsCard>
          ))}
          <AppBox className="w-1/2 mx-auto" flexDirection="column">
            <AppButton
              fullWidth
              size="lg"
              variant="light"
              label="View Detail Stats"
            />
          </AppBox>
        </AppBox>
      </AppBox>
    </AppBox>
  );
}
