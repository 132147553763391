import Arsenal from "../../assets/images/clubs/Arsenal.png";
import { AppBox, AppContainer } from "../../commons/components";
import { Outlet } from "react-router-dom";
import DetailHeader from "./components/DetailHeader";

export default function TeamDetail() {
  const teamInfo = {
    logo: Arsenal,
    name: "Arsenal",
    label: "2nd in Premier League | 2022/23",
  };

  const tabItems = [
    {
      label: "Summary",
      value: "summary",
      href: "summary",
    },
    {
      label: "Profile",
      value: "profile",
      href: "profile",
    },
    {
      label: "Squad",
      value: "squad",
      href: "squad",
    },
    {
      label: "Events",
      value: "events",
      href: "events",
    },
    {
      label: "Statistics",
      value: "statistics",
      href: "statistics",
    },
    {
      label: "Standings",
      value: "standings",
      href: "standings",
    },
  ];

  return (
    <AppContainer>
      <AppBox mt="md" flexDirection="column" gap="md">
        <DetailHeader tabItems={tabItems} headerInfo={teamInfo}></DetailHeader>
        <Outlet />
      </AppBox>
    </AppContainer>
  );
}
