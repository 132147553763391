import {
  AppAvatar,
  AppBox,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../commons/components";

import AppBorderBox from "../../../commons/components/BorderBox";
import IllustrationBadge from "../../../commons/components/illustrations/illustrationBadge";
import { SurfaceColor } from "../../../commons/types/core-types";

interface BestPlayerProps {
  id: number;
  name: string;
  role: string;
  avatar: string;
  clubLogo: string;
  date: string;
  opponent: string;
  awardTitle: string;
}

export default function BestPlayerCard({
  playerInfo,
}: {
  playerInfo: BestPlayerProps;
}) {
  return (
    <AppPaper className="best-player-card" padding="xs" shadow="xs">
      <AppBorderBox border={["Bottom"]} pb="2xs">
        <AppBox justifyContent="space-between">
          <AppBox gap="2xs" alignItems="center">
            <AppAvatar
              size="md"
              src={playerInfo.avatar}
              username={playerInfo.name}
              variant="outline"
            />
            <AppBox flexDirection="column">
              <AppTitle as="h6">{"Aaron Ramsdale"}</AppTitle>
              <AppText as="label" size="sm">
                {playerInfo.role}
              </AppText>
            </AppBox>
          </AppBox>
          <AppAvatar
            size="xs"
            src={playerInfo.clubLogo}
            username={"Arsenal"}
            variant="outline"
          />
        </AppBox>
      </AppBorderBox>
      <AppBox justifyContent="center" gap="2xs" py="2xs">
        <AppText>Date: {playerInfo.date}</AppText>
        <AppBorderBox px="2xs" border={["Left"]}>
          <AppText>Opponent: {playerInfo.opponent}</AppText>
        </AppBorderBox>
      </AppBox>
      <AppPaper radius="sm" bgColor={SurfaceColor.surface3} padding="none">
        <AppBox p="2xs" gap="3xs" justifyContent="center" alignItems="center">
          <IllustrationBadge width="24" height="24" />
          <AppTitle as="h6" color="accent">
            {playerInfo.awardTitle}
          </AppTitle>
        </AppBox>
      </AppPaper>
    </AppPaper>
  );
}
