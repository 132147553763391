import {
  AppBox,
  AppDoughnutChart,
  AppGridBox,
  AppPaper,
  AppResultBadge,
  InfoCard,
} from "../../../commons/components";
import { SurfaceColor } from "../../../commons/types/core-types";

interface TeamStatsProps {
  matchPlayed: number;
  goal: number;
  gaolConceded: number;
  cleanSheet: number;
  win: number;
  draw: number;
  loss: number;
}

export default function TeamStatisticsCard({ data }: { data: TeamStatsProps }) {
  const winnPercentage = (data.win * 100) / data.matchPlayed;
  const drawPercentage = (data.draw * 100) / data.matchPlayed;
  const lossPercentage = (data.loss * 100) / data.matchPlayed;
  return (
    <AppPaper shadow="xs" padding="sm">
      <AppBox
        alignItems="center"
        justifyContent="center"
        gap="md"
        flexWrap="wrap"
      >
        <AppBox>
          <AppBox flexDirection="column" alignItems="center" gap="3xs">
            <AppDoughnutChart
              color="success"
              value={winnPercentage}
              info={{ value: data.win, label: "Win" }}
            />
            <AppResultBadge status="W" />
          </AppBox>
          <AppBox flexDirection="column" alignItems="center" gap="3xs">
            <AppDoughnutChart
              color="info"
              value={drawPercentage}
              info={{ value: data.draw, label: "Draws" }}
            />
            <AppResultBadge status="D" />
          </AppBox>
          <AppBox flexDirection="column" alignItems="center" gap="3xs">
            <AppDoughnutChart
              value={lossPercentage}
              color="danger"
              info={{ value: data.loss, label: "Losses" }}
            />
            <AppResultBadge status="L" />
          </AppBox>
        </AppBox>
        <AppGridBox gap="xs" columns={2} style={{ flexGrow: "1" }}>
          <InfoCard
            paperBgColor={SurfaceColor.surface3}
            label={"Match Played"}
            title={data.matchPlayed}
          ></InfoCard>
          <InfoCard
            paperBgColor={SurfaceColor.surface3}
            label={"Goals"}
            title={data.goal}
          ></InfoCard>
          <InfoCard
            paperBgColor={SurfaceColor.surface3}
            label={"Goals Conceded"}
            title={data.gaolConceded}
          ></InfoCard>
          <InfoCard
            paperBgColor={SurfaceColor.surface3}
            label={"Clean Sheets"}
            title={data.cleanSheet}
          ></InfoCard>
        </AppGridBox>
      </AppBox>
    </AppPaper>
  );
}
