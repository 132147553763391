import AppBox from "../Box";
import AppText from "../Text";

interface ResultBadgeProps {
  status: string;
}
export enum MatchStatusEnum {
  Win = "W",
  Loss = "L",
  Draw = "D",
}
export default function AppResultBadge({ status }: ResultBadgeProps) {
  const gameStatusMapping = {
    [MatchStatusEnum.Win]: { className: "success", label: "W" },
    [MatchStatusEnum.Loss]: { className: "danger", label: "L" },
    [MatchStatusEnum.Draw]: { className: "info", label: "D" },
  };

  const { className, label } = gameStatusMapping[status as MatchStatusEnum];
  return (
    <AppBox
      alignItems="center"
      justifyContent="center"
      className={`result-badge color-${className}`}>
      <AppText as="span" fontWeight="extrabold" size="lg" textAlign="center">
        {label}
      </AppText>
    </AppBox>
  );
}
