import { ReactNode } from "react";
import { AppBox, AppDivider, AppPaper, AppText } from "../../../commons/components";
import IconCalendarClock from "../../../commons/components/icons/calenadr-clock";
import IconLocation from "../../../commons/components/icons/location";
import IconPeopleTeam from "../../../commons/components/icons/people-team";
import IconWhistle from "../../../commons/components/icons/whistle";
import React from "react";

interface IMatchInfo {
  location: string;
  date: Date;
  attendance: number;
  referee: string;
}

interface MatchInfoProps {
  match: IMatchInfo;
}
interface IDateType {
  date: string;
  time: string;
}

export default function MatchInfoCard({ match }: MatchInfoProps) {
  const matchInfoItems = [
    {
      icon: <IconLocation />,
      title: "Location:",
      value: match.location,
    },
    {
      icon: <IconCalendarClock />,
      title: "Date & time:",
      value: {
        date: match.date.toDateString(),
        time: match.date.toLocaleTimeString(),
      },
    },
    {
      icon: <IconPeopleTeam />,
      title: "Attendance:",
      value: match.attendance,
    },
    {
      icon: <IconWhistle />,
      title: "Referee:",
      value: match.referee,
    },
  ];

  return (
    <AppPaper shadow="xs" padding="xs">
      <AppBox flexDirection="column">
        {matchInfoItems.map((item, index) => (
          <React.Fragment key={index}>
            <AppBox gap="2xs" alignItems="center">
              <AppBox gap="3xs" alignItems="center">
                <AppText as="span" color="accent">
                  {item.icon}
                </AppText>
                <AppText fontWeight="bold">{item.title}</AppText>
              </AppBox>
              {item.title === "Date & time:" ? (
                <AppBox>
                  <AppText>{(item.value as IDateType).date}</AppText>
                  <AppDivider orientation="vertical" mx="3xs" />
                  <AppText>{(item.value as IDateType).time}</AppText>
                </AppBox>
              ) : (
                <AppText>{item.value as ReactNode}</AppText>
              )}
            </AppBox>
            {item.title !== "Referee:" && <AppDivider my="2xs" />}
          </React.Fragment>
        ))}
      </AppBox>
    </AppPaper>
  );
}
