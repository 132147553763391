import React from "react";
import {
  AppAvatar,
  AppBox,
  AppText,
  AppTitle,
} from "../../../commons/components";
import IconFootball from "../../../commons/components/icons/football";
import IconCardYellow from "../../../commons/components/icons/card-yellow";
import IconArrowRepeatAll from "../../../commons/components/icons/arrow-repeate-all";
import IconCardDoubleYellow from "../../../commons/components/icons/card-double-yellow";
import IconCardRed from "../../../commons/components/icons/card-red";

type MatchHalvesType =
  | "firstHalf"
  | "secondHalf"
  | "firstHalfExtra"
  | "secondHalfExtra";
type GameEventType = "S" | "YC" | "DYC" | "RC" | "G";

interface IGameEvent {
  time: number;
  matchHalf: MatchHalvesType;
  type: GameEventType[];
}

export interface IMatchEvent {
  officialMatchTime: number;
  additionalTime1: number;
  additionalTime2: number;
  additionalTime3?: number;
  additionalTime4?: number;
  teamA: {
    name: string;
    avatar: string;
    event?: IGameEvent[];
  };
  teamB: {
    name: string;
    avatar: string;
    event?: IGameEvent[];
  };
}

interface MatchProps {
  matchInfo: IMatchEvent;
}

const getEventIcon = (eventType: GameEventType) => {
  switch (eventType) {
    case "S":
      return <IconArrowRepeatAll />;
    case "YC":
      return <IconCardYellow />;
    case "DYC":
      return <IconCardDoubleYellow />;
    case "RC":
      return <IconCardRed />;
    case "G":
      return <IconFootball />;
    default:
      return <IconFootball />;
  }
};

const formatMatchTime = (eventTime: number, half: MatchHalvesType) => {
  var addTime = 0;
  switch (half) {
    case "firstHalf":
      if (eventTime > 45) {
        addTime = eventTime - 45;
        return `45+${addTime}'`;
      } else {
        return `${eventTime}'`;
      }
    case "secondHalf":
      if (eventTime > 90) {
        addTime = eventTime - 90;
        return `90+${addTime}'`;
      } else {
        return `${eventTime}'`;
      }
    case "firstHalfExtra":
      if (eventTime > 105) {
        addTime = eventTime - 105;
        return `105+${addTime}'`;
      } else {
        return `${eventTime}'`;
      }
    case "secondHalfExtra":
      if (eventTime > 120) {
        addTime = eventTime - 120;
        return `120+${addTime}'`;
      } else {
        return `${eventTime}'`;
      }
    default:
      return `${eventTime}'`;
  }
};

const getTotalTime = (match: IMatchEvent) => {
  if (match.officialMatchTime === 90) {
    return (
      match.officialMatchTime + match.additionalTime1 + match.additionalTime2
    );
  } else {
    return (
      match.officialMatchTime +
      match.additionalTime1 +
      match.additionalTime2 +
      (match.additionalTime3 as number) +
      (match.additionalTime4 as number)
    );
  }
};

const MatchTimeline = ({ matchInfo }: MatchProps) => {
  const totalTime = getTotalTime(matchInfo);
  return (
    <AppBox flexDirection="column" gap="xs">
      <AppTitle as="h5">Match Timeline</AppTitle>
      <AppBox
        my="lg"
        alignItems="center"
        style={{
          background: `linear-gradient(to bottom,rgba(var(--bg-translucent-primary)) 50%,rgba(var(--bg-translucent-accent)) 50%)`,
        }}
      >
        <AppBox flexDirection="column" alignItems="center">
          <AppBox p="2xs" alignItems="center" justifyContent="center">
            <AppAvatar
              size="xs"
              username={matchInfo.teamA.name}
              src={matchInfo.teamA.avatar}
            />
          </AppBox>
          <AppBox p="2xs" alignItems="center" justifyContent="center">
            <AppAvatar
              size="xs"
              username={matchInfo.teamB.name}
              src={matchInfo.teamB.avatar}
            />
          </AppBox>
        </AppBox>
        <AppBox
          p="2xs"
          alignItems="center"
          justifyContent="center"
          className="match-timeline-container"
        >
          <AppBox className="match-timeline"></AppBox>
          {matchInfo.teamA.event &&
            matchInfo.teamA.event.map((eventItem) => (
              <AppBox
                className="match-timeline-event-top"
                flexDirection="column-reverse"
                alignItems="center"
                style={{
                  left: `${(eventItem.time / totalTime) * 100}%`,
                }}
              >
                {eventItem.type.map((eventType: GameEventType) =>
                  getEventIcon(eventType)
                )}
                <AppText size="sm">
                  {formatMatchTime(eventItem.time, eventItem.matchHalf)}
                </AppText>
              </AppBox>
            ))}
          {matchInfo.teamB.event &&
            matchInfo.teamB.event.map((eventItem) => (
              <AppBox
                className="match-timeline-event-bottom"
                flexDirection="column"
                alignItems="center"
                style={{
                  left: `${(eventItem.time / totalTime) * 100}%`,
                }}
              >
                {eventItem.type.map((eventType: GameEventType) =>
                  getEventIcon(eventType)
                )}
                <AppText size="sm">
                  {formatMatchTime(eventItem.time, eventItem.matchHalf)}
                </AppText>
              </AppBox>
            ))}
        </AppBox>
      </AppBox>
    </AppBox>
  );
};

export default MatchTimeline;
