import {
  AppAvatar,
  AppBox,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../commons/components";

interface PlayerCardProps {
  name: string;
  avatar: string;
  position: string;
  number: number;
}

export default function PlayerCard({
  playerInfo,
}: {
  playerInfo: PlayerCardProps;
}) {
  return (
    <AppPaper padding="xs" shadow="xs">
      <AppBox justifyContent="space-between" alignItems="center">
        <AppBox gap="xs" alignItems="center">
          <AppAvatar
            username={playerInfo.name}
            src={playerInfo.avatar}
            size="lg"
            variant="outline"
          />
          <AppBox flexDirection="column">
            <AppTitle as="h6">{playerInfo.name}</AppTitle>
            <AppText as="label" size="sm">
              {playerInfo.position}
            </AppText>
          </AppBox>
        </AppBox>
        <AppTitle as="h5" color="primary">
          {playerInfo.number}
        </AppTitle>
      </AppBox>
    </AppPaper>
  );
}
