import { AppBox, AppPaper } from "../../../commons/components";
import StatisticsBar from "./StatisticsBar";

export default function TeamDefenceStats() {
  return (
    <AppPaper shadow="xs" padding="xs">
      <AppBox flexDirection="column" gap="sm">
        <StatisticsBar label={"Clean Sheets"} value={450} fillValue={80} />
        <StatisticsBar label={"Goal Conceded"} value={1191} fillValue={60} />
        <StatisticsBar
          label={"Goal Conceded per match"}
          value={100}
          fillValue={30}
        />
        <StatisticsBar label={"Saves"} value={1154} fillValue={20} />
        <StatisticsBar label={"Tackles"} value={12107} fillValue={10} />
        <StatisticsBar label={"Tackle Success"} value={"70%"} fillValue={70} />
      </AppBox>
    </AppPaper>
  );
}
