import { AppBox } from "../../../commons/components";
import TopStatsCard from "../../matches/components/TopStatsCard";
import Odegard from "../../../assets/images/players/Odegaard.png";
import Jesus from "../../../assets/images/players/Jesus.png";
import Nelson from "../../../assets/images/players/Nelson.png";

export default function TournamentPlayerStatistics() {
  const playerStats = [
    {
      id: 0,
      name: "Reiss Nelson",
      avatar: Nelson,
      number: 24,
      position: "Forward",
      team: "Arsenal",
      statValue: 500,
    },
    {
      id: 1,
      name: "Martin Odegaard",
      avatar: Odegard,
      number: 8,
      position: "Midfielder",
      team: "Arsenal",
      statValue: 300,
    },
    {
      id: 2,
      name: "Gabriel Jesus",
      avatar: Jesus,
      number: 9,
      position: "Forward",
      team: "Arsenal",
      statValue: 48,
    },
    {
      id: 3,
      name: "Gabriel Jesus",
      avatar: Jesus,
      number: 9,
      position: "Forward",
      team: "Arsenal",
      statValue: 48,
    },
    {
      id: 4,
      name: "Gabriel Jesus",
      avatar: Jesus,
      number: 9,
      position: "Forward",
      team: "Arsenal",
      statValue: 48,
    },
  ];
  return (
    <AppBox flexDirection="column" gap="sm">
      {playerStats.map((player, index) => (
        <TopStatsCard key={index} player={player} rank={player.id + 1} />
      ))}
    </AppBox>
  );
}
