import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from "react-router-dom";
import {
  Home,
  Login,
  TeamSummary,
  TeamEvents,
  TeamProfile,
  MatchDetail,
  MatchSummary,
  MatchCommentary,
  MatchCommentaryFull,
  MatchCommentaryKey,
  Matches,
  TournamentDetail,
  TournamentSummary,
  TournamentProfile,
  TournamentTeams,
  TournamentNews,
  TournamentEvents,
  TournamentStatistics,
  TournamentPlayerStatistics,
  MatchLineup,
  MatchStatistics,
  MatchFormTeam1,
  TeamStatistics,
  TeamSquad,
  TeamDetail,
  TeamDefenceStats,
  TeamStanding,
  PlayerDetail,
  PlayerProfile,
  PlayerEvents,
  PlayerNews,
  MatchStandings,
  TournamentStandings,
} from "./pages";
import Components from "./pages/Components";
import Sidenav from "./commons/components/ui-components/Sidenav";
import { AppBox, NotFound, Toolbar } from "./commons/components";

const PublicLayout = () => (
  <>
    <Sidenav />
    <AppBox className="app-content-wrapper" flexDirection="column">
      <Toolbar />
      <Outlet />
    </AppBox>
  </>
);

export const HOME_PATH = "/";
export const LOGIN_PATH = "/login";
export const COMPONENTS = "/components";
export const TEAM_DETAIL = "/team-detail";
export const MATCH_DETAIL = "/match-detail";
export const MATCH_SUMMARY = "/match-detail/summary";
export const MATCH_COMMENTARY = "/match-detail/commentary";
export const MATCH_COMMENTARY_ALL = "/match-detail/commentary/all-commentary";
export const MATCH_COMMENTARY_KEY = "/match-detail/commentary/key-events";
export const MATCH_LINEUP = "/match-detail/lineups";
export const MATCH_STATISTICS = "/match-detail/statistics";
export const MATCH_STATISTICS_T1 = "/match-detail/statistics/team-1";
export const MATCH_STATISTICS_T2 = "/match-detail/statistics/team-2";
export const MATCH_STANDINGS = "/match-detail/standings";
export const TEAM_SUMMARY = "summary";
export const TEAM_EVENTS = "events";
export const TEAM_PROFILE = "profile";
export const TEAM_SQUAD = "squad";
export const TEAM_STANDING = "standings";
export const TEAM_STATISTICS = "statistics";
export const TEAM_DEFENCE = "defence";
export const MATCHES = "/matches";
export const TOURNAMENT_DETAIL = "/tournament-detail";
export const TOURNAMENT_SUMMARY = "/tournament-detail/summary";
export const TOURNAMENT_PROFILE = "/tournament-detail/profile";
export const TOURNAMENT_TEAMS = "/tournament-detail/teams";
export const TOURNAMENT_NEWS = "/tournament-detail/news";
export const TOURNAMENT_EVENTS = "/tournament-detail/events";
export const TOURNAMENT_STATISTICS = "/tournament-detail/statistics";
export const TOURNAMENT_PLAYER_STATISTICS =
  "/tournament-detail/statistics/players-stats";
export const TOURNAMENT_TEAM_STATISTICS =
  "/tournament-detail/statistics/teams-stats";
export const TOURNAMENT_STANDING = "/tournament-detail/standings";

//Player routes
export const PLAYER_DETAIL = "/player-detail";
export const PLAYER_PROFILE = "profile";
export const PLAYER_EVENTS = "events";
export const PLAYER_NEWS = "news";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route>
        <Route element={<PublicLayout />}>
          <Route path={HOME_PATH} element={<Home />} />
          <Route path={LOGIN_PATH} element={<Login />} />
          <Route path={COMPONENTS} element={<Components />} />
          <Route path={TEAM_DETAIL} element={<TeamDetail />}>
            <Route path={""} element={<Navigate to={TEAM_SUMMARY} />} />
            <Route path={TEAM_SUMMARY} element={<TeamSummary />} />
            <Route path={TEAM_EVENTS} element={<TeamEvents />} />
            <Route path={TEAM_PROFILE} element={<TeamProfile />} />
            <Route path={TEAM_SQUAD} element={<TeamSquad />} />
            <Route path={TEAM_STANDING} element={<TeamStanding />} />
            <Route path={TEAM_STATISTICS} element={<TeamStatistics />}>
              <Route path={""} element={<Navigate to={TEAM_DEFENCE} />} />
              <Route path={TEAM_DEFENCE} element={<TeamDefenceStats />} />
              <Route path={"*"} element={<NotFound />} />
            </Route>
            <Route path={"*"} element={<NotFound />} />
          </Route>
          <Route path={MATCH_DETAIL} element={<MatchDetail />}>
            <Route path={""} element={<Navigate to={MATCH_SUMMARY} />} />
            <Route path={MATCH_SUMMARY} element={<MatchSummary />} />
            <Route path={MATCH_COMMENTARY} element={<MatchCommentary />}>
              <Route
                path={""}
                element={<Navigate to={MATCH_COMMENTARY_ALL} />}
              />
              <Route
                path={MATCH_COMMENTARY_ALL}
                element={<MatchCommentaryFull />}
              />
              <Route
                path={MATCH_COMMENTARY_KEY}
                element={<MatchCommentaryKey />}
              />
            </Route>
            <Route path={MATCH_LINEUP} element={<MatchLineup />}>
              <Route path={"*"} element={<NotFound />} />
            </Route>
            <Route path={MATCH_STATISTICS} element={<MatchStatistics />}>
              <Route
                path={""}
                element={<Navigate to={MATCH_STATISTICS_T1} />}
              />
              <Route path={MATCH_STATISTICS_T1} element={<MatchFormTeam1 />} />
              <Route path={MATCH_STATISTICS_T2} element={<MatchFormTeam1 />} />
            </Route>
            <Route path={MATCH_STANDINGS} element={<MatchStandings />} />
            <Route path={"*"} element={<NotFound />} />
          </Route>
          <Route path={MATCHES} element={<Matches />}>
            <Route path={"*"} element={<NotFound />} />
          </Route>
          <Route path={TOURNAMENT_DETAIL} element={<TournamentDetail />}>
            <Route path={""} element={<Navigate to={TOURNAMENT_SUMMARY} />} />
            <Route path={TOURNAMENT_SUMMARY} element={<TournamentSummary />} />
            <Route path={TOURNAMENT_PROFILE} element={<TournamentProfile />} />
            <Route path={TOURNAMENT_TEAMS} element={<TournamentTeams />} />
            <Route path={TOURNAMENT_NEWS} element={<TournamentNews />} />
            <Route path={TOURNAMENT_EVENTS} element={<TournamentEvents />} />
            <Route
              path={TOURNAMENT_STATISTICS}
              element={<TournamentStatistics />}
            >
              <Route
                path={""}
                element={<Navigate to={TOURNAMENT_PLAYER_STATISTICS} />}
              />
              <Route
                path={TOURNAMENT_PLAYER_STATISTICS}
                element={<TournamentPlayerStatistics />}
              />
              <Route
                path={TOURNAMENT_TEAM_STATISTICS}
                element={<TournamentPlayerStatistics />}
              />
            </Route>
            <Route
              path={TOURNAMENT_STANDING}
              element={<TournamentStandings />}
            />
            <Route path={"*"} element={<NotFound />} />
          </Route>
          <Route path={PLAYER_DETAIL} element={<PlayerDetail />}>
            <Route path={""} element={<Navigate to={PLAYER_PROFILE} />} />
            <Route path={PLAYER_PROFILE} element={<PlayerProfile />} />
            <Route path={PLAYER_EVENTS} element={<PlayerEvents />} />
            <Route path={PLAYER_NEWS} element={<PlayerNews />} />
            <Route path={"*"} element={<NotFound />} />
          </Route>
        </Route>
      </Route>
    </>
  )
);

export default router;
