import AppBox from "../Box";
import AppText from "../Text";
import IconArrowRepeatAll from "../icons/arrow-repeate-all";
import IconCardDoubleYellow from "../icons/card-double-yellow";
import IconCardRed from "../icons/card-red";
import IconCardYellow from "../icons/card-yellow";
import IconChat from "../icons/chat";
import IconFootball from "../icons/football";

interface CommentaryBadgeProps {
  status: string;
}
export enum CommentaryStatusEnum {
  Comment = "C",
  Substitute = "S",
  YellowCard = "YC",
  DoubleYellowCard = "DYC",
  RedCard = "RC",
  Goal = "G",
  OwnGoal = "OG",
}
export default function AppCommentaryBadge({ status }: CommentaryBadgeProps) {
  const gameStatusMapping = {
    [CommentaryStatusEnum.Comment]: { className: "info", label: <IconChat /> },
    [CommentaryStatusEnum.Substitute]: { className: "info", label: <IconArrowRepeatAll /> },
    [CommentaryStatusEnum.YellowCard]: { className: "dark", label: <IconCardYellow /> },
    [CommentaryStatusEnum.DoubleYellowCard]: { className: "dark", label: <IconCardDoubleYellow /> },
    [CommentaryStatusEnum.RedCard]: { className: "dark", label: <IconCardRed /> },
    [CommentaryStatusEnum.Goal]: { className: "success", label: <IconFootball /> },
    [CommentaryStatusEnum.OwnGoal]: { className: "danger", label: <IconFootball /> },
  };

  const { className, label } = gameStatusMapping[status as CommentaryStatusEnum];
  return (
    <AppBox alignItems="center" justifyContent="center" className={`result-badge color-${className}`}>
      <AppText as="span" fontWeight="extrabold" size="lg" textAlign="center">
        {label}
      </AppText>
    </AppBox>
  );
}
