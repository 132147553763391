import { AppBox, AppPaper } from "../../../commons/components";
import StandingTableHeader from "./StandingTableHeader";
import StandingTableRow from "./StandingTableRow";
import Arsenal from "../../../assets/images/clubs/Arsenal.png";
import ManchesterCity from "../../../assets/images/clubs/ManCity.png";
import NewCastle from "../../../assets/images/clubs/Newcastel.png";
import Liverpool from "../../../assets/images/clubs/Liverpool.png";
import Brightom from "../../../assets/images/clubs/Brighton.png";
import Everton from "../../../assets/images/clubs/Everton.png";
import ManchesterUnited from "../../../assets/images/clubs/ManUnited.png";
import Chelsea from "../../../assets/images/clubs/Chelsea.png";

interface StandingTableProps {
  activeValue?: Array<number>;
  activeColors?: Array<string>;
}

export default function TeamStandingTable({
  activeValue,
  activeColors = ["accent"],
}: StandingTableProps) {
  const items = [
    {
      name: "Manchester United",
      avatar: ManchesterUnited,
      id: 1,
      gp: 100,
      w: 90,
      d: 5,
      l: 5,
      gf: 88,
      ga: 40,
    },
    {
      name: "Manchester City",
      avatar: ManchesterCity,
      id: 2,
      gp: 100,
      w: 90,
      d: 5,
      l: 5,
      gf: 88,
      ga: 40,
    },
    {
      name: "Everton",
      avatar: Everton,
      id: 3,
      gp: 100,
      w: 90,
      d: 5,
      l: 5,
      gf: 88,
      ga: 40,
    },
    {
      name: "Liverpool",
      avatar: Liverpool,
      id: 4,
      gp: 100,
      w: 90,
      d: 5,
      l: 5,
      gf: 88,
      ga: 40,
    },
    {
      name: "Arsenal",
      avatar: Arsenal,
      id: 5,
      gp: 100,
      w: 90,
      d: 5,
      l: 5,
      gf: 88,
      ga: 40,
    },
    {
      name: "Brightom & Hove Albion",
      avatar: Brightom,
      id: 6,
      gp: 100,
      w: 90,
      d: 5,
      l: 5,
      gf: 88,
      ga: 400,
    },
    {
      name: "New Castle United",
      avatar: NewCastle,
      id: 7,
      gp: 100,
      w: 90,
      d: 5,
      l: 5,
      gf: 88,
      ga: 88,
    },
    {
      name: "Chelsea",
      avatar: Chelsea,
      id: 8,
      gp: 100,
      w: 90,
      d: 5,
      l: 5,
      gf: 90,
      ga: 88,
    },
  ];

  let activeCounter = 0;

  const tableItems = items.map((item, index) => {
    const isActive = activeValue?.includes(item.id);
    let activeColor = "";
    if (isActive) {
      activeColor = activeColors[activeCounter % activeColors.length];
      activeCounter++;
    }

    return (
      <StandingTableRow
        isActive={isActive}
        key={item.id}
        rowItems={item}
        index={index}
        activeColor={activeColor}
      />
    );
  });

  return (
    <AppPaper shadow="xs" padding="none" className="standing-table">
      <table>
        <thead>
          <StandingTableHeader />
        </thead>
        <tbody>{tableItems}</tbody>
      </table>
    </AppPaper>
  );
}
