import {
  AppAvatar,
  AppBox,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../commons/components";
import HeaderBackGround from "../../../assets/images/backgrounds/Elements.svg";
import AppTabs, { TabItem } from "../../../commons/components/Tabs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface DetailHeaderProps {
  tabItems: Array<TabItem>;
  headerInfo: IHeaderItems;
}

interface IHeaderItems {
  logo: string;
  name: string;
  label: string;
}

export default function DetailHeader({
  tabItems,
  headerInfo,
}: DetailHeaderProps) {
  const [currentTab, setCurrentTab] = useState("summary");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const location = useLocation();

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  return (
    <AppPaper shadow="xs" padding="none">
      <AppBox
        alignItems="center"
        gap="xs"
        p="sm"
        style={{
          backgroundImage: `url(${HeaderBackGround})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          borderBottom: "1px solid rgb(var(--border-default))",
        }}
      >
        <AppAvatar
          username={headerInfo.name}
          size="2xl"
          variant="outline"
          src={headerInfo.logo}
        />
        <AppBox flexDirection="column">
          <AppTitle as="h4">{headerInfo.name}</AppTitle>
          <AppBox gap="3xs">
            <AppText size="md">{headerInfo.label}</AppText>
            {/* <AppBorderBox border={["Left"]} pl="3xs">
              <AppText size="md">{headerInfo.year}</AppText>
            </AppBorderBox> */}
          </AppBox>
        </AppBox>
      </AppBox>
      <AppBox p="sm">
        <AppTabs
          as="a"
          onClick={(e) => setActiveItem(e)}
          activeValue={currentTab}
          tabItems={tabItems}
        />
      </AppBox>
    </AppPaper>
  );
}
