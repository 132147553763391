import AppBox from "../Box";

interface ISelectProps {
  options: string[];
  className?: string;
}

export default function Select({ options, className }: ISelectProps) {
  const classNames = [className].filter(Boolean).join(" ");
  return (
    <AppBox className={classNames}>
      <select className="ee-input" style={{ width: "100%" }}>
        {options.map((item) => (
          <option key={item}>{item}</option>
        ))}
      </select>
    </AppBox>
  );
}
