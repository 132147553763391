import AppBox from "../Box";
import AppText from "../Text";
import IconArrowDown from "../icons/arrow-down";
import IconArrowUp from "../icons/arrow-up";
import IconCardDoubleYellow from "../icons/card-double-yellow";
import IconCardRed from "../icons/card-red";
import IconCardYellow from "../icons/card-yellow";
import IconFootball from "../icons/football";

interface CommentaryBadgeProps {
  status: string;
}
export enum LineupStatusEnum {
  SubstituteOut = "SO",
  SubstituteIn = "SI",
  YellowCard = "YC",
  DoubleYellowCard = "DYC",
  RedCard = "RC",
  Goal = "G",
  OwnGoal = "OG",
}
export default function AppLineUpBadge({ status }: CommentaryBadgeProps) {
  const gameStatusMapping = {
    [LineupStatusEnum.SubstituteOut]: { className: "danger", label: <IconArrowUp /> },
    [LineupStatusEnum.SubstituteIn]: { className: "success", label: <IconArrowDown /> },
    [LineupStatusEnum.YellowCard]: { className: "dark", label: <IconCardYellow /> },
    [LineupStatusEnum.DoubleYellowCard]: { className: "dark", label: <IconCardDoubleYellow /> },
    [LineupStatusEnum.RedCard]: { className: "dark", label: <IconCardRed /> },
    [LineupStatusEnum.Goal]: { className: "success", label: <IconFootball /> },
    [LineupStatusEnum.OwnGoal]: { className: "danger", label: <IconFootball /> },
  };

  const { className, label } = gameStatusMapping[status as LineupStatusEnum];
  return (
    <AppBox alignItems="center" justifyContent="center" className={`result-badge color-${className}`}>
      <AppText as="span" fontWeight="extrabold" size="lg" textAlign="center">
        {label}
      </AppText>
    </AppBox>
  );
}
