import { EnsueHttpClient } from "../ensue-react-system/http/ensue-http-client";
import  axios from "axios";
import { EnsueSession } from "../ensue-react-system/utilities/ensue-session";
import { EnsueStorage } from "../ensue-react-system/utilities/ensue-storage";
import { User } from "./models/user";
import { LoginService } from "../api-services/login-service";
import { UriProvider } from "./utilities/uri-provider";
import { HttpRequestInterceptor } from "./utilities/http-request-interceptor";


const uriProvider = new UriProvider();

export const httpClient = new EnsueHttpClient(axios);
export const appStorage = new EnsueStorage("taliWeb");
export const appSession = new EnsueSession(appStorage, "authUser", new User(), "authToken");

const httpRequestInterceptor = new HttpRequestInterceptor(appSession);
httpClient.setRequestInterceptor(httpRequestInterceptor);

/**** API Caller Services Instances ******/

export const loginService = new LoginService(httpClient, uriProvider );