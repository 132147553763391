import {
  AppBox,
  AppButton,
  AppGridBox,
  AppTitle,
} from "../../commons/components";
import NewsTimelineCard, {
  INewsItem,
} from "../../commons/components/ui-components/NewsTimelineCard";
import MatchCommentaryGroup from "./components/MatchCommentaryGroup";
import MatchInfoCard from "./components/MatchInfoCard";
import MatchStatisticsGroup from "./components/MatchStatisticsGroup";
import MatchTimeline, { IMatchEvent } from "./components/MatchTimeline";
import Arsenal from "../../assets/images/clubs/Arsenal.png";
import Chelsea from "../../assets/images/clubs/Chelsea.png";

export const newsData: INewsItem[] = [
  {
    id: 0,
    date: new Date(),
    news: [
      {
        title: "This is a news card with vertical content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: (
          <img
            alt=""
            src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
          />
        ),
      },
      {
        title: "This is a news card with horizontal content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: (
          <img
            alt=""
            src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
          />
        ),
      },
      {
        title: "This is a news card with horizontal content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: (
          <img
            alt=""
            src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
          />
        ),
      },
    ],
  },
  {
    id: 1,
    date: new Date(),
    news: [
      {
        title: "This is a news card with vertical content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: (
          <img
            alt=""
            src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
          />
        ),
      },
      {
        title: "This is a news card with horizontal content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: (
          <img
            alt=""
            src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
          />
        ),
      },
      {
        title: "This is a news card with horizontal content",
        label:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
        media: (
          <img
            alt=""
            src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
          />
        ),
      },
    ],
  },
];

export default function MatchSummary() {
  const matchInfo = {
    location: "Emirates Stadium, London",
    date: new Date(),
    attendance: 60000,
    referee: "Robert Jones",
  };

  const newsData: INewsItem[] = [
    {
      id: 0,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
      ],
    },
    {
      id: 1,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
      ],
    },
  ];

  const match: IMatchEvent = {
    officialMatchTime: 90,
    additionalTime1: 5,
    additionalTime2: 2,
    teamA: {
      avatar: Arsenal,
      name: "Arsenal",
      event: [
        { matchHalf: "firstHalf", time: 32, type: ["G"] },
        { matchHalf: "firstHalf", time: 49, type: ["G", "RC"] },
        { matchHalf: "secondHalf", time: 88, type: ["G"] },
        { matchHalf: "secondHalf", time: 91, type: ["S"] },
      ],
    },
    teamB: {
      avatar: Chelsea,
      name: "Chelsea",
      event: [
        { matchHalf: "secondHalf", time: 18, type: ["YC"] },
        { matchHalf: "firstHalf", time: 32, type: ["G"] },
        { matchHalf: "secondHalf", time: 70, type: ["DYC"] },
      ],
    },
  };

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <AppBox flexDirection="column" gap="md">
        <MatchTimeline matchInfo={match} />
        <MatchCommentaryGroup />
        <AppBox flexDirection="column" gap="sm">
          <MatchStatisticsGroup />
          <AppBox className="w-1/2 mx-auto" flexDirection="column">
            <AppButton
              as="a"
              fullWidth
              size="lg"
              variant="light"
              label="View Full Statistics"
            />
          </AppBox>
        </AppBox>
      </AppBox>
      <AppBox flexDirection="column" gap="sm">
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">Match Information</AppTitle>
          <MatchInfoCard match={matchInfo} />
        </AppBox>
        <NewsTimelineCard news={newsData} timelineType="small" />
      </AppBox>
    </AppGridBox>
  );
}
