import {
  AppActionIcon,
  AppBox,
  AppDatePicker,
  AppGridBox,
  AppTitle,
} from "../../commons/components";
import NewsTimelineCard, {
  INewsItem,
} from "../../commons/components/ui-components/NewsTimelineCard";
import Arsenal from "../../assets/images/clubs/Arsenal.png";
import NewCastleLogo from "../../assets/images/clubs/Newcastel.png";
import UpcomingMatchCard from "../team-detail/components/UpcomingMatchCard";
import IconFilter from "../../commons/components/icons/filter";

export default function TournamentEvents() {
  const newsData: INewsItem[] = [
    {
      id: 0,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
      ],
    },
    {
      id: 1,
      date: new Date(),
      news: [
        {
          title: "This is a news card with vertical content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
        {
          title: "This is a news card with horizontal content",
          label:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim ex tempora eaque et debitis qui omnis nisi iusto optio beatae facere consectetur mollitia voluptate praesentium, maiores incidunt repellendus. Blanditiis!",
          media: (
            <img
              alt=""
              src="https://www-cdn.eumetsat.int/files/styles/16_9_large/s3/2023-04/mtg-i1.jpg?h"
            />
          ),
        },
      ],
    },
  ];
  const upcomingMatch = [
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
    {
      date: "28 May, 2023",
      league: "English Premier League",
      id: 1,
      teamB: {
        name: "New Castle",
        logo: NewCastleLogo,
      },
      teamA: {
        name: "Arsenal",
        logo: Arsenal,
      },
    },
  ];

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <AppBox flexDirection="column" gap="sm">
        <AppBox flexDirection="column" gap="xs">
          <AppBox gap="xs">
            <AppBox className="flex-1">
              <AppTitle as="h5">{"Awards & Trophies"}</AppTitle>
            </AppBox>
            <AppActionIcon
              icon={<IconFilter />}
              color="gray"
              variant="default"
            />
          </AppBox>
          <AppDatePicker />
        </AppBox>
        <AppBox flexDirection="column" gap="sm">
          {upcomingMatch.map((match, index) => (
            <UpcomingMatchCard key={index} data={match} />
          ))}
        </AppBox>
      </AppBox>
      <NewsTimelineCard news={newsData} timelineType="small" />
    </AppGridBox>
  );
}
