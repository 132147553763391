import Arsenal from "../../assets/images/clubs/Arsenal.png";
import Chelsea from "../../assets/images/clubs/Chelsea.png";
import { AppBox, AppContainer } from "../../commons/components";
import { Outlet } from "react-router-dom";
import MatchDetailHeader from "./components/MatchDetailHeader";

export const matchInfo = {
  date: new Date() as unknown as string,
  league: "English Premiere League",
  status: "completed",
  teamA: {
    logo: Arsenal,
    name: "Arsenal",
    goal: 3,
  },
  teamB: {
    logo: Chelsea,
    name: "Chelsea",
    goal: 1,
  },
};
export default function MatchDetail() {
  return (
    <AppBox flexDirection="column" gap="md" py="md">
      <AppContainer>
        <AppBox flexDirection="column" gap="md">
          <MatchDetailHeader match={matchInfo}></MatchDetailHeader>
          <Outlet />
        </AppBox>
      </AppContainer>
    </AppBox>
  );
}
