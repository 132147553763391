import TimelineCard from "./TimelineCard";
import NewsCard from "./NewsCard";
import AppTitle from "../Title";
import AppBox from "../Box";
import { AppGridBox } from "..";

export interface INews {
  media: React.ReactNode;
  title: string;
  label: string;
}

export interface INewsItem {
  id: number;
  date: Date;
  news: Array<INews>;
}

export interface INewsTimelineCardProps {
  news: Array<INewsItem>;
  timelineType: "big" | "small";
  title?: string;
}

export default function NewsTimelineCard({
  title = "News Timeline",
  news,
  timelineType,
}: INewsTimelineCardProps) {
  return (
    <AppBox flexDirection="column">
      <AppTitle as="h5" pb="xs">
        {title}
      </AppTitle>
      <AppBox flexDirection="column" gap="md">
        {news.map((newsItem, index) => (
          <TimelineCard key={index} date={newsItem.date}>
            {newsItem.news.length > 0 && (
              <NewsCard
                previewChildren={newsItem.news[0].media}
                orientation="vertical"
                title={newsItem.news[0].title}
                label={newsItem.news[0].label}
              />
            )}
            {timelineType === "small" ? (
              <>
                {newsItem.news.slice(1).map((data, dataIndex) => (
                  <NewsCard
                    key={dataIndex}
                    previewChildren={data.media}
                    orientation="horizontal"
                    title={data.title}
                    label={data.label}
                  />
                ))}
              </>
            ) : (
              <AppGridBox columns={2} gap="sm">
                {newsItem.news.slice(1).map((data, dataIndex) => (
                  <NewsCard
                    key={dataIndex}
                    previewChildren={data.media}
                    orientation="vertical"
                    title={data.title}
                    label={data.label}
                  />
                ))}
              </AppGridBox>
            )}
          </TimelineCard>
        ))}
      </AppBox>
    </AppBox>
  );
}
