import PlayerInfo from "./components/PlayerInfo";
import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppTitle,
} from "../../commons/components";
import Odengaard from "../../assets/images/players/Odegaard.png";
import Arsenal from "../../assets/images/clubs/Arsenal.png";
import SouthHampton from "../../assets/images/clubs/Southampton.png";
import Leicester from "../../assets/images/clubs/Leicester.png";
import WestHam from "../../assets/images/clubs/WestHam.png";
import BestPlayerCard from "../team-detail/components/BestPlayerCard";
import AssociatedTeamOrLeague from "../team-detail/components/AssociatedTeamOrLeague";
import NewsTimelineCard from "../../commons/components/ui-components/NewsTimelineCard";
import NewsFIrstImage from "../../assets/images/news/image 130.png";
import NewsSecondImage from "../../assets/images/news/Image Block.png";
import TeamImage from "../../assets/images/news/team.png";
import NewsSecond from "../../assets/images/news/ProfileNews1.png";
import NewsThird from "../../assets/images/news/ProfileNews2.png";
import NewsFourth from "../../assets/images/news/ProfileNews3.png";
import PlayerAttribute from "./components/PlayerAttribute";

export default function PlayerProfile() {
  const selectOptions = ["2023/24", "2022/23", "2021/22"];
  const playerOfTheGame = [
    {
      id: 1,
      name: "Martin Odegaard",
      role: "Midfielder",
      avatar: Odengaard,
      clubLogo: Arsenal,
      date: "30 Jan, 2023",
      opponent: "Bournemouth",
      awardTitle: "Goal of the Season",
    },
    {
      id: 2,
      name: "Martin Odegaard",
      role: "Midfielder",
      avatar: Odengaard,
      clubLogo: Arsenal,
      date: "30 Jan, 2023",
      opponent: "Bournemouth",
      awardTitle: "Game Changer",
    },
  ];

  const associatedTeams = [
    {
      id: 1,
      logo: Arsenal,
    },
    {
      id: 2,
      logo: SouthHampton,
    },
    {
      id: 3,
      logo: Leicester,
    },
    {
      id: 4,
      logo: WestHam,
    },
  ];

  const news = [
    {
      id: 1,
      date: new Date("2023/06/20"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton. He was really good and I remember a few teams looking at him, he was a really good player.",
          media: <img src={NewsFIrstImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecondImage} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={TeamImage} alt="" />,
        },
      ],
    },
    {
      id: 2,
      date: new Date("2023/06/22"),
      news: [
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsSecond} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsThird} alt="" />,
        },
        {
          title: "We are facing the MLS All-Stars this summer!",
          label:
            "We had a centre-back who was very good, his name was Ayo and he got scouted by Charlton.",
          media: <img src={NewsFourth} alt="" />,
        },
      ],
    },
  ];

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox flexDirection="column" gap="md">
        <PlayerInfo />
        <PlayerAttribute />
        <NewsTimelineCard news={news} timelineType="big" />
      </AppBox>
      <AppBox flexDirection="column" gap="md">
        <AppBox flexDirection="column" gap="sm">
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">{"Awards & Trophies"}</AppTitle>
            <AppSelect options={selectOptions}></AppSelect>
          </AppBox>
          {playerOfTheGame.map((item) => (
            <BestPlayerCard key={item.id} playerInfo={item} />
          ))}
        </AppBox>
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">{"Associated Teams"}</AppTitle>
          <AppBox flexWrap="wrap" gap="xs">
            {associatedTeams.map((item) => (
              <AssociatedTeamOrLeague key={item.id} logo={item.logo} />
            ))}
          </AppBox>
        </AppBox>
      </AppBox>
    </AppGridBox>
  );
}
