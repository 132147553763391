export default function IconPeopleSwap() {
  return (
    <svg
      className="svg-icon"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.937 15.5A2.001 2.001 0 0 0 13 14l-9-.001-.15.005A2.001 2.001 0 0 0 2 16V17.5l.005.23C2.165 21.044 5.778 22 8.5 22c.855 0 1.798-.094 2.694-.326l-.681-.682a1.75 1.75 0 0 1-.401-.622 9.718 9.718 0 0 1-1.295.125L8.5 20.5l-.317-.005c-1.263-.039-2.44-.308-3.266-.753C3.95 19.222 3.5 18.509 3.5 17.5v-1.501l.01-.101a.506.506 0 0 1 .136-.253.51.51 0 0 1 .253-.136l.101-.01 10.937.001Z"
        fill="currentColor"
      />
      <path
        d="M14.78 21.723a.75.75 0 0 1-1.06 1.06l-2.5-2.498a.75.75 0 0 1 0-1.061l2.5-2.501a.75.75 0 1 1 1.06 1.06l-1.22 1.22h6.878l-1.219-1.22a.75.75 0 1 1 1.061-1.06l2.5 2.502a.75.75 0 0 1 0 1.06l-2.5 2.499a.75.75 0 1 1-1.06-1.061l1.22-1.22h-6.88l1.22 1.22Z"
        fill="currentColor"
      />
      <path
        d="M22 17v.03l-1.012-1.014a1.742 1.742 0 0 0-.71-.432.497.497 0 0 0-.188-.076L20 15.5h-4.051a2.957 2.957 0 0 0-.595-1.34L15.22 14H20c1.054 0 1.918.816 1.994 1.85L22 16v1ZM13 7.5a4.5 4.5 0 1 0-9 0 4.5 4.5 0 0 0 9 0Zm-7.5 0c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3ZM21 8.5a3.5 3.5 0 1 0-7 0 3.5 3.5 0 0 0 7 0Zm-5.5 0c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2Z"
        fill="currentColor"
      />
    </svg>
  );
}
