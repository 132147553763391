import { useState } from "react";
import { NavLink } from "react-router-dom";
import IconTrophy from "../icons/trophy";
import IconAlert from "../icons/alert";
import { ReactComponent as SmallLogo } from "../../../assets/images/logo/logo-small.svg";
import { ReactComponent as LargeLogo } from "../../../assets/images/logo/logo-large.svg";
import AppBox from "../Box";
import AppText from "../Text";
import IconDismiss from "../icons/dismiss";
import IconRibbonStar from "../icons/ribbon-star";
import AppDivider from "../Divider";
import IconMoreCircle from "../icons/more";
import IconDataPie from "../icons/data-pie";
import IconPeopleSwap from "../icons/people-swap";

export type NavItemProps = {
  link: string;
  label: string;
  icon: React.ReactNode;
};

const Sidenav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(false);

  const toggleSidenav = () => {
    if (isPinned) {
      setIsPinned(false);
      setIsOpen(false);
    } else {
      setIsPinned(true);
      setIsOpen(true);
    }
  };

  const handleMouseEnterLeave = () => {
    // if (!isPinned) {
    //   setIsOpen((prevIsOpen) => !prevIsOpen);
    // }
  };

  const navItem: Array<NavItemProps> = [
    {
      link: "/matches",
      label: "Matches",
      icon: <IconRibbonStar />,
    },
    {
      link: "/team-detail",
      label: "Team Details",
      icon: <IconTrophy />,
    },
    {
      link: "/match-detail",
      label: "Match Details",
      icon: <IconAlert />,
    },
    {
      link: "/tournament-detail",
      label: "Tournament Details",
      icon: <IconMoreCircle />,
    },
    {
      link: "/player-detail",
      label: "Player",
      icon: <IconPeopleSwap />,
    },
    {
      link: "/components",
      label: "Component",
      icon: <IconDataPie />,
    },
  ];

  return (
    <nav
      className={`ee-side-nav ${isOpen ? "opened" : "closed"} ${
        isPinned && "pinned"
      }`}
      onMouseEnter={handleMouseEnterLeave}
      onMouseLeave={handleMouseEnterLeave}
    >
      <AppBox flexDirection="column">
        <AppBox justifyContent="space-between" onClick={toggleSidenav}>
          {isOpen ? (
            <>
              <AppBox className="ee-side-nav-logo ee-logo-large">
                {<LargeLogo />}
              </AppBox>
              {isPinned && (
                <button className="btn" onClick={toggleSidenav}>
                  <IconDismiss />
                </button>
              )}
            </>
          ) : (
            <AppBox className="ee-side-nav-logo">{<SmallLogo />}</AppBox>
          )}
        </AppBox>
        <AppDivider mt="xs" />
        <AppBox flexDirection="column" gap="xs" pt="lg">
          {navItem.map((item: NavItemProps, index) => (
            <li className={`ee-side-nav-item `} key={item.label}>
              <NavLink className={`ee-side-nav-link`} to={item.link}>
                <span className="ee-side-nav-icon">{item.icon}</span>
                <span className="ee-side-nav-label">
                  {isOpen && <AppText as="label">{item.label}</AppText>}
                </span>
              </NavLink>
            </li>
          ))}
        </AppBox>
      </AppBox>
    </nav>
  );
};

export default Sidenav;
