import {
  AppBox,
  AppGridBox,
  AppPaper,
  AppSelect,
  AppText,
  AppTitle,
} from "../../../commons/components";
import EChartsReact from "echarts-for-react";
import IconInfo from "../../../commons/components/icons/info";
import IconPeopleSwap from "../../../commons/components/icons/people-swap";

interface IPlayerAttribute {
  index: number;
  name: number;
  label: string;
}
export default function PlayerAttribute() {
  const indicators = [
    {
      index: 0,
      name: 72,
      label: "ATT",
    },
    {
      index: 1,
      label: "DEF",
      name: 70,
    },
    {
      index: 2,
      label: "DEF",
      name: 40,
    },
    {
      index: 3,
      label: "TAC",
      name: 50,
    },
    {
      index: 4,
      label: "TAC",
      name: 60,
    },
  ];

  const chartOptions = {
    radar: {
      indicator: indicators,
      name: {
        formatter: (value: number) => {
          const item = getAxisLabel(value);
          if (item.index > 2) {
            return `{b|${value}} {a|${item.label}}`;
          }
          return `{a|${item.label}} {b|${value}}`;
        },
        rich: {
          a: {
            color: "#000",
            fontSize: 14,
            fontWeight: 700,
          },
          b: {
            backgroundColor: "rgba(66, 49, 191, 1)",
            padding: 6,
            fontSize: 16,
            color: "rgb(255, 255, 255, 1)",
            fontWeight: 700,
            borderRadius: 4,
          },
        },
      },

      splitNumber: 4,
      splitLine: {
        show: false,
      },

      axisLine: {
        show: false,
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: [
            "rgba(251, 76, 11, 0.6)",
            "rgba(251, 76, 11, 0.48)",
            "rgba(251, 76, 11, 0.36)",
            "rgba(251, 76, 11, 0.24)",
            "rgba(251, 76, 11, 0.12)",
          ],
        },
      },
    },
    series: [
      {
        name: "Player Attributes",
        type: "radar",
        data: [
          {
            value: [60, -5, 70, -5, 60],
            itemStyle: {
              color: "rgba(251, 76, 11, 1)",
            },
          },
        ],
        lineStyle: {
          width: 1,
        },
        symbolSize: 0,
      },
    ],
  };

  const getAxisLabel = (value: number) => {
    let itemToReturn: IPlayerAttribute = {
      index: 0,
      name: 0,
      label: "",
    };
    for (let i = 0; i < indicators.length; i++) {
      const item = indicators[i];
      if (value === item?.name) {
        itemToReturn = item;
      }
    }
    return itemToReturn;
  };
  const selectOptions = ["2023/24", "2022/23", "2021/22"];

  return (
    <AppBox flexDirection="column" gap="sm" className="player-attribute">
      <AppBox flexDirection="column" gap="xs">
        <AppBox justifyContent="space-between">
          <AppTitle as="h5">Attribute Overview</AppTitle>
          <IconInfo />
        </AppBox>
        <AppSelect options={selectOptions} className="w-1/2" />
      </AppBox>
      <AppPaper padding="sm" shadow="xs">
        <EChartsReact option={chartOptions} className="attribute-chart" />
        <AppGridBox columns={2} gap="sm" pt="sm">
          <AppBox displayBlock className="attribute-compare">
            <input
              type="text"
              placeholder="Search players to compare"
              className="ee-input"
            />
            <AppText className="swap-icon">
              <IconPeopleSwap />
            </AppText>
          </AppBox>

          <AppBox gap="2xs">
            <IconInfo />
            <AppText size="sm" fontWeight="medium">
              Tap on attribute overview to display average values for this
              position.
            </AppText>
          </AppBox>
        </AppGridBox>
      </AppPaper>
    </AppBox>
  );
}
