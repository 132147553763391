import { IillustrationsProps } from "../interface";

export default function IllustrationBall({
  height = "56",
  width = "56",
}: IillustrationsProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_248_561)">
        <path
          d="M56 28C56 12.536 43.464 -3.8147e-06 28 -3.8147e-06C12.536 -3.8147e-06 0 12.536 0 28C0 43.464 12.536 56 28 56C43.464 56 56 43.464 56 28Z"
          fill="#C4BFEB"
        />
        <path
          d="M55.6661 32.2864C55.5336 33.1992 55.3423 34.0677 55.1362 34.9363L54.9742 34.6713C52.8102 34.7743 50.705 34.8185 48.7176 34.8185H46.5536L38.5746 45.8153C38.9574 48.0824 39.3254 50.7028 39.561 53.5441C38.9721 53.809 38.3685 54.074 37.7502 54.2948C37.5147 51.4536 37.1614 48.789 36.7786 46.4925L25.0016 42.665C20.8013 44.9487 16.3568 46.7512 11.7524 48.0383L11.4873 50.585C8.76233 48.5754 6.43169 46.08 4.61249 43.2244L11.2224 46.1686C15.235 45.0183 19.133 43.5006 22.867 41.6345V27.4725L9.13193 17.7417L0.991028 20.951C2.42249 15.3779 5.54396 10.3846 9.92687 6.65655L10.5157 16.4021L23.7649 25.8238L36.7197 21.5987L36.9406 20.951C37.7375 15.9336 37.9794 10.8437 37.6619 5.77329L30.3012 0.0761108C34.4068 0.400177 38.3891 1.63208 41.9605 3.68284L39.5904 5.30219C39.9658 10.6831 39.699 16.0896 38.7955 21.4074L38.5452 22.1876L46.3034 32.8311C49.0415 32.8311 52.1919 32.8311 55.3422 32.6986L55.6661 32.2864Z"
          fill="#4231BF"
        />
        <path
          opacity="0.2"
          d="M24.8838 43.3716C36.4045 43.3716 45.7439 34.0322 45.7439 22.5115C45.7439 10.9907 36.4045 1.65131 24.8838 1.65131C13.363 1.65131 4.02362 10.9907 4.02362 22.5115C4.02362 34.0322 13.363 43.3716 24.8838 43.3716Z"
          fill="#F2EFED"
        />
      </g>
      <defs>
        <clipPath id="clip0_248_561">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
