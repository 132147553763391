import { AppBox, AppContainer } from "../../commons/components";
import TournamentDetailHeader from "./conponents/TournamentDetailHeader";
import { Outlet } from "react-router-dom";
import EPL from "../../assets/images/leagues/PremierLeague.jpg";

export default function TournamentDetail() {
  const TournamentInfo = {
    logo: EPL,
    name: "English Premiere League",
    year: "2022/23",
  };

  return (
    <AppBox flexDirection="column" gap="md" py="md">
      <AppContainer>
        <AppBox flexDirection="column" gap="md">
          <TournamentDetailHeader tournamentData={TournamentInfo}></TournamentDetailHeader>
          <Outlet />
        </AppBox>
      </AppContainer>
    </AppBox>
  );
}
