import { CSSProperties, ReactNode } from "react";
import { Spaces, SpacingOptions } from "../types/core-types";

interface BoxProps extends SpacingOptions {
  alignItems?: CSSProperties["alignItems"];
  className?: string;
  children?: ReactNode;
  displayBlock?: boolean;
  flexDirection?: CSSProperties["flexDirection"];
  flexWrap?: CSSProperties["flexWrap"];
  justifyContent?: CSSProperties["justifyContent"];
  gap?: Spaces;
  style?: CSSProperties | undefined;
  onClick?: () => void;
}

export default function AppBox({
  flexDirection = "row",
  justifyContent,
  alignItems,
  flexWrap,
  gap,
  className,
  style,
  children,
  displayBlock,
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
  onClick,
}: BoxProps) {
  const boxStyles: CSSProperties = !displayBlock
    ? {
        display: "flex",
        justifyContent,
        flexDirection,
        alignItems,
        flexWrap,
      }
    : {
        display: "block",
      };

  const classNames = [
    p && `p-${p}`,
    pt && `pt-${pt}`,
    pr && `pr-${pr}`,
    pb && `pb-${pb}`,
    pl && `pl-${pl}`,
    px && `px-${px}`,
    py && `py-${py}`,
    m && `m-${m}`,
    mt && `mt-${mt}`,
    mr && `mr-${mr}`,
    mb && `mb-${mb}`,
    ml && `ml-${ml}`,
    mx && `mx-${mx}`,
    my && `my-${my}`,
    gap && `gap-${gap}`,
    className,
  ]
    .filter(Boolean)
    .join(" ");

  const mergedStyles = {
    ...boxStyles,
    ...style,
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div style={mergedStyles} className={classNames} onClick={handleClick}>
      {children}
    </div>
  );
}
